import { Typography, Switch, Spin, message } from "antd";
import { useTranslation } from "react-i18next";
import ItemContent from "../shared/ItemContent";
import { IconTopic } from "../../assets/svg/ConversationScript/IconConersationScript";
import styles from "./index.module.scss"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { editSettingNotifycation } from "../../api/conversationScript";
import { setNotifySetting } from "../../reducers/conversationScriptSlice";
import { useState } from "react";
declare const location: Location; 
const NotificationSetting = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const setting = useSelector((state: RootState) => state.conversationScript.notifySetting)
    const [loading, setLoading] = useState<boolean>(false);

    const swichNumber = (num: number) => {
        if (num === 0) {
            return 1;
        } else {
            return 0;
        }

    }

    const handleSwitch = async (type: string) => {
        setLoading(true);
        let data = {
        }
        if (setting) {
            if (type !== "mess") {
                data = {
                    setting_id: setting?._id,
                    is_enable_sound_remind: swichNumber(setting?.is_enable_sound_remind),
                    is_enable_sound_message: setting?.is_enable_sound_message,
                }
                const newSetting = {
                    ...setting,
                    is_enable_sound_remind: swichNumber(setting?.is_enable_sound_remind),
                }
                dispatch(setNotifySetting(newSetting))
            } else {
                data = {
                    setting_id: setting?._id,
                    is_enable_sound_message: swichNumber(setting?.is_enable_sound_message),
                    is_enable_sound_remind: setting?.is_enable_sound_remind,
                }
                const newSetting = {
                    ...setting,
                    is_enable_sound_message: swichNumber(setting?.is_enable_sound_message),
                }
                dispatch(setNotifySetting(newSetting))
            }
        }

        try {
            const editRes = await editSettingNotifycation(data);
            if (editRes?.status) {
                setLoading(false)
                message.success(t("alertmess.succes.notify-setting"))
                setTimeout(()=>{location.reload()},3000)
               
            } else {
                setLoading(false)
                message.error(t("alertmess.error.notify-setting"))
            }
        } catch (error) {
            setLoading(false)
            message.error(t("alertmess.error.notify-setting"))
        }
    }

    const renderNotiSetting = () => {
        return (
            <>
                {setting && !setting.isload ? (
                    <div style={{ minHeight: "150px", minWidth: "450px" }}>
                        <div className="flex flex-wrap gap-3">
                            <div className={`${styles.notiSetting} flex items-center flex-1 `}>
                                <Switch
                                    onChange={() => {
                                        if (!loading) {
                                            handleSwitch("mess")
                                        }else {
                                            message.warning(t("alertmess.warning.too-fast"))
                                        }
                                    }}
                                    checked={setting.is_enable_sound_message === 1 ? true : false} className={`${styles.switchbox}`}
                                    checkedChildren={<p>{t("button.on")}</p>}
                                    unCheckedChildren={<p style={{ color: "red" }}>{t("button.off")}</p>}
                                />
                                <p className={`${styles.notiTitle} ms-3 `}>{t("sound-setting.newchat")}</p>
                            </div>
                            <div className={`${styles.notiSetting} flex items-center flex-1 `}>
                                <Switch
                                    onChange={() => {
                                        if (!loading) {
                                            handleSwitch("remind")
                                        }else {
                                            message.warning(t("alertmess.warning.too-fast"))
                                        }
                                    }}
                                    checked={setting.is_enable_sound_remind === 1 ? true : false} className={`${styles.switchbox}`}
                                    checkedChildren={<p>{t("button.on")}</p>}
                                    unCheckedChildren={<p style={{ color: "red" }}>{t("button.off")}</p>}
                                />
                                <p className={`${styles.notiTitle} ms-3 `}>{t("sound-setting.reminder-calendar")}</p>
                            </div>

                        </div>
                    </div>
                ) : (
                    <div className="flex items-center "
                        style={{ justifyContent: "center" }}
                    >
                        <Spin></Spin>
                    </div>
                )}
            </>
        )
    }
    return (
        <>
            <div className={`m-auto 2xl:max-w-[1200px] max-w-[1100px]`}>
                {/* <Typography className={`text-2xl`}>{t('sound-setting.header')}</Typography> */}
                <div className={`mt-6 flex gap-4`}>
                    <div className={`w-full`}
                    >
                        <ItemContent
                            childContent={renderNotiSetting()}
                            iconTitle={<IconTopic />}
                            title={t('sound-setting.header')}
                        />
                    </div>

                </div>
            </div>
        </>
    )
}

export default NotificationSetting;