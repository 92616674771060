export enum PLATFORM {
  PLATFORM_FACEBOOK_GROUP = 3,
  PLATFORM_FACEBOOK = 1,
  PLATFORM_TIKTOK = 2,
  PLATFORM_ZALO_OA = 4,
  PLATFORM_YOUTUBE = 5,
  PLATFORM_INSTAGRAM = 6,
  PLATFORM_LINKEDIN = 7,
  PLATFORM_PINTEREST = 8,
  NULL = 0,
}
