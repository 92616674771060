import {Typography} from "antd";
import Topic from "./topic";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {loadListReplySample, loadListTopic} from "../../api/conversationScript";
import {Note} from "./note";
import {useDispatch,useSelector} from "react-redux";
import {setReplySamples, setReplyTopics} from "../../reducers/conversationScriptSlice";
import { RootState } from "../../store";
import Tags from "./topic";
import { useTranslation } from "react-i18next";
import { getUserInfor } from "../../helper/common";

export default function ConversationTag() {
  const dispatch = useDispatch()
  const {t} = useTranslation();
  const [loadingTopic, setLoadingTopic] = useState(true)

  const tags =  useSelector((state: RootState) => state.conversationScript.tags);
  const userInfor = getUserInfor();
  useEffect(()=>{
    if (tags) {
      setLoadingTopic(false);
    }
  },[tags])

  return (
    <div className={`m-auto 2xl:max-w-[1200px] max-w-[1100px]`}>
      <Typography className={`text-2xl`}>{t('tag.header')}</Typography>
      <div className={`mt-6 flex gap-4`}>
        <div className={`w-[542px]`}>
          <Tags loadingTopic={loadingTopic}/>
        </div>
        <div className={`flex-1`}>
        <Note/>
        </div>
      </div>
    </div>
  )
}