import CampaignBot from "./campaign-bot";

export default function CampaignComponent() {
  return (
    <div className={` m-auto 2xl:max-w-[100%]  max-w-[100%]`}>
      {/* <div className={`mt-6 flex gap-4`}> */}
      <CampaignBot />
      {/* </div> */}
    </div>
  );
}
