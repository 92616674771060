import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { channelItems, groupChannel } from "../dto";


interface IInitialState {
    channels: channelItems[] | undefined;
    groupChannels: groupChannel[]| undefined;
}

const initialState: IInitialState = {
    channels: undefined,
    groupChannels :undefined
};


export const channels = createSlice({
    name: "channels",
    initialState,
    reducers: {
        setChannels(state: any, action: PayloadAction<channelItems[] | undefined>) {
            state.channels = action.payload
        },
        setGroupChannels(state: any, action: PayloadAction<groupChannel[] | undefined>) {
            state.groupChannels = action.payload
        },
    },
});

export const {
    setChannels,
    setGroupChannels
} = channels.actions;
export default channels.reducer;
