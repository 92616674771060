import {API} from "../libs/client";
import {IReplyTopicItem} from "../dto/reply-topic";
import {IReplySampleItem} from "../dto/reply-sample";

export const loadListTopic = async (projectId : string): Promise<IReplyTopicItem[]> => {
  const uri = `projects/${projectId}/reply-topic`;
  const res = await API.get(uri);
  return res.data.data;
};

export const createTopic = async (projectId : string, data: {name: string, color: string}): Promise<string> => {
  const uri = `projects/${projectId}/reply-topic`;
  const res = await API.post(uri, data);
  return res.data.data;
};

export const editTopic = async (projectId : string, data: {name: string, color: string,id: string}): Promise<string> => {
  const uri = `projects/${projectId}/reply-topic/${data.id}`;
  const newdata = {name: data.name , color : data.color}
  const res = await API.put(uri, newdata);
  return res.data.data;
};

export const delTopic = async (projectId : string, topicId: string): Promise<boolean> => {
  const uri = `projects/${projectId}/reply-topic/${topicId}`;
  const res = await API.delete(uri);
  return res.data.data;
};

export const loadListReplySample = async (projectId : string): Promise<IReplySampleItem[]> => {
  const uri = `projects/${projectId}/reply-sample`;
  const res = await API.get(uri);
  return res.data.data;
};

export const addReplySample = async (projectId : string, data: {}): Promise<IReplySampleItem> => {
  const uri = `projects/${projectId}/reply-sample`;
  const res = await API.post(uri, data);
  return res.data.data;
};

export const updateReplySample = async (projectId : string, data: {}, replySampleId : string): Promise<IReplySampleItem> => {
  const uri = `projects/${projectId}/reply-sample/${replySampleId}`;
  console.log(replySampleId)
  const res = await API.put(uri, data);
  return res.data.data;
};

export const delReplySample = async (projectId : string, sampleId: string): Promise<boolean> => {
  const uri = `projects/${projectId}/reply-sample/${sampleId}`;
  const res = await API.delete(uri);
  return res.data.data;
};


export const getListTag = async (data:{}) => {
  const uri = `projects/bot-chat/tag`;
  const res = await API.get(uri, data);
  return res.data;
};

export const createTag = async (data: {name: string, color: string ,project_id : string , priority?: string , description?:string})=> {
  const uri = `projects/bot-chat/tag`;
  const res = await API.post(uri, data);
  return res.data;
};
export const editTag = async ( tagId : string ,data: {name: string, color: string ,project_id : string , priority?: string , description?:string })=> {
  const uri = `projects/bot-chat/tag/${tagId}`;
  const res = await API.put(uri, data);
  return res.data;
};

export const deleteTag = async ( tagId : string ,data: {})=> {
  const uri = `projects/bot-chat/tag/${tagId}`;
  const res = await API.delete(uri,data);
  return res.data;
};
export const updateConversationTags = async (conversationId: string, data:{})=> {
  const uri = `projects/bot-chat/conversations/${conversationId}/tags`;
  const res = await API.put(uri, data);
  return res.data;
};

export const getSettingNotifycation = async (data : {})=> {
  const uri = `/projects/users/setting`;
  const res = await API.get(uri,data);
  return res.data;
};
export const editSettingNotifycation = async (data : {})=> {
  const uri = `/projects/users/setting/edit`;
  const res = await API.put(uri,data);
  return res.data;
};