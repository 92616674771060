import {IReplyTopicItem} from "../../../dto/reply-topic";
import {Popover} from "antd";
import styles from './custom.module.scss'
import {IconTrash} from "../../../assets/svg/ConversationScript/IconConersationScript";
import { tagItem } from "../../../dto";

export default function ItemTag({item,handleDelTag, handleEditTag} : {
  item: tagItem
  handleEditTag : () => void 
  handleDelTag : (id: string) => void
}
  
) {
  const genIconDel = () => {
    return <div className={`cursor-pointer`}  
    onClick={() => handleDelTag(item._id)}>
      <IconTrash/>
    </div>
  }
 
  return (
    <Popover placement={'right'}  title={genIconDel} overlayClassName={`${styles.PopoverCustom}`}>
      <div className={`flex items-center mr-7 cursor-pointer`}
      onClick={()=>handleEditTag()}
      >
        <div className={` h-8 text-base border ps-3 flex-1 flex items-center  border-gray-100 rounded-l-lg`}>{item.name}</div>
        <div className={`border border-gray-100 h-8 pl-2 border-l-0 rounded-r-lg`} style={{backgroundColor: `${item.color}`,width:"160px"}}></div>
      </div>
    </Popover>

  )
}