import { useTranslation } from "react-i18next";
import IconConversationScript from "../assets/svg/LeftMenuSetting/IconConversationScript";
import IconChannelView from "../assets/svg/LeftMenuSetting/IconChannelView";
import {useLocation , Link} from "react-router-dom";
import IconConversationTag from "../assets/svg/LeftMenuSetting/IconConversationTag";

export default function LeftMenu() {
  const location = useLocation();
  const {t} = useTranslation();
  const listLeftMenu = [
    {
      id: 1,
      name: t('script-setting.trigger'),
      path: '/setting/conversation-script',
      icon: <IconConversationScript isActive={location.pathname === '/setting/conversation-script'}/>
    },
    {
      id: 2,
      name: t('tag.trigger'),
      path: '/setting/conversation-tag',
      icon: <IconConversationTag isActive={location.pathname === '/setting/conversation-tag'}/>
    },
    {
      id: 3,
      name: t('sound-setting.header'),
      path: '/setting/sound-notification',
      icon: <IconConversationScript isActive={location.pathname === '/setting/sound-notification'}/>
    },
    {
      id: 4,
      name: t('channel_view.header'),
      path: '/setting/channel-view-type',
      icon: <IconChannelView isActive={location.pathname === '/setting/channel-view-type'}/>
    }
  ]
  return (
    <div className={`lg:w-[250px] 2xl:w-[280px] min-h-screen bg-white pt-4`}>
      {
        listLeftMenu.map((item, key) => {
          return <Link to={item.path} key={key} className={`flex ${location.pathname === item.path ? "bg-gray-100 font-medium" : ""} gap-2 items-center py-4 px-7 hover:bg-gray-100 hover:font-medium text-base cursor-pointer`}>
            <div className={`pt-1`}>{item.icon}</div>
            <div className="hidden lg:block">{item.name}</div>
          </Link>
        })
      }
    </div>
  )
}