export default function IconExpand() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.5V4.5M1.5 1.5H4.5M1.5 1.5L5 5M1.5 12.5V9.5M1.5 12.5H4.5M1.5 12.5L5 9M12.5 1.5H9.5M12.5 1.5V4.5M12.5 1.5L9 5M12.5 12.5H9.5M12.5 12.5V9.5M12.5 12.5L9 9"
        stroke="#6B7280"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
