import { Switch, Typography } from "antd";
import { ItemHeader } from "./ItemHeader";
import { useEffect } from "react";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { useTranslation } from "react-i18next";

export const AutomaticScript = ({
  itemSelectedToEdit,
  setData,
}: {
  itemSelectedToEdit?: ICreateAutomaticScript | IUpdateAutomaticScrip;
  setData: any;
}) => {
  const { t } = useTranslation();

  const onChange = (checked: boolean, key: string) => {
    setData((pre: any) => ({ ...pre, [key]: checked ? 1 : 0 }));
    console.log(`switch to ${checked}`);
  };

  useEffect(() => {
    setData((pre: any) => ({
      ...pre,
      is_auto_hide_comment: itemSelectedToEdit?.is_auto_hide_comment || 0,
      is_auto_like_comment: itemSelectedToEdit?.is_auto_like_comment || 0,
      is_auto_reply_comment: itemSelectedToEdit?.is_auto_reply_comment || 0,
      is_auto_send_message_after_reply_comment:
        itemSelectedToEdit?.is_auto_send_message_after_reply_comment || 0,
      is_auto_delete_comment: itemSelectedToEdit?.is_auto_delete_comment || 0,
      is_auto_block_customer: itemSelectedToEdit?.is_auto_block_customer || 0,
    }));
  }, []);

  return (
    <>
      <div className={`flex p-4 bg-gray-100 items-center gap-2 rounded-t-lg`}>
        <ItemHeader title={t("campaign.action")} />
      </div>
      <div
        className={`p-4 flex gap-y-5 bg-white items-center rounded-b-lg flex-wrap`}
      >
        <div className="flex items-center w-[50%]">
          <Switch
            defaultChecked={itemSelectedToEdit?.is_auto_like_comment === 1}
            onChange={(e) => onChange(e, "is_auto_like_comment")}
          />
          <span>
            <Typography className="ml-2 text-sm font-[550]">
              {t("campaign.auto_like_comment")}
            </Typography>
          </span>
        </div>

        <div className="flex items-center w-[50%]">
          <Switch
            defaultChecked={itemSelectedToEdit?.is_auto_reply_comment === 1}
            onChange={(e) => onChange(e, "is_auto_reply_comment")}
          />
          <span>
            <Typography className="ml-2 text-sm font-[550]">
              {t("campaign.auto_rep_comment")}
            </Typography>
          </span>
        </div>

        <div className="flex items-center w-[50%]">
          <Switch
            defaultChecked={itemSelectedToEdit?.is_auto_hide_comment === 1}
            onChange={(e) => onChange(e, "is_auto_hide_comment")}
          />
          <span>
            <Typography className="ml-2 text-sm font-[550]">
              {t("campaign.auto_hide_comment")}
            </Typography>
          </span>
        </div>

        <div className="flex items-center w-[50%]">
          <Switch
            defaultChecked={
              itemSelectedToEdit?.is_auto_send_message_after_reply_comment === 1
            }
            onChange={(e) =>
              onChange(e, "is_auto_send_message_after_reply_comment")
            }
          />
          <span>
            <Typography className="ml-2 text-sm font-[550]">
              {t("campaign.auto_send_message_comment")}
            </Typography>
          </span>
        </div>

        <div className="flex items-center w-[50%]">
          <Switch
            defaultChecked={itemSelectedToEdit?.is_auto_delete_comment === 1}
            onChange={(e) => onChange(e, "is_auto_delete_comment")}
          />
          <span>
            <Typography className="ml-2 text-sm font-[550]">
              {t("campaign.auto_delete_comment")}
            </Typography>
          </span>
        </div>

        <div className="flex items-center w-[50%]">
          <Switch
            defaultChecked={itemSelectedToEdit?.is_auto_block_customer === 1}
            onChange={(e) => onChange(e, "is_auto_block_customer")}
          />
          <span>
            <Typography className="ml-2 text-sm font-[550]">
              {t("campaign.auto_block_customer")}
            </Typography>
          </span>
        </div>
      </div>
    </>
  );
};
