export default function IconConversationScript({isActive} : {isActive: boolean}) {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3128_18247)">
      <path d="M13.9083 0C14.9206 0 15.755 0.834407 15.755 1.84676V10.0546C15.755 11.0669 14.9206 11.9013 13.9083 11.9013H11.8892L12.2419 15.3256C12.2675 15.5757 12.1217 15.8346 11.8948 15.9428C11.668 16.051 11.3754 16.0021 11.1967 15.8258L7.25939 11.9014H2.00679C0.994441 11.9014 0.160034 11.067 0.160034 10.0547V1.84686C0.160034 0.834507 0.994441 0.000100584 2.00679 0.000100584L13.9083 0ZM13.9083 1.23117H2.00694C1.65507 1.23117 1.39135 1.49489 1.39135 1.84676V10.0546C1.39135 10.4064 1.65507 10.6702 2.00694 10.6702H7.51603C7.67714 10.6702 7.83744 10.7359 7.95207 10.8497L10.8441 13.7288L10.6004 11.3434C10.5684 11.0044 10.8689 10.6725 11.2096 10.6701H13.9083C14.2602 10.6701 14.5239 10.4064 14.5239 10.0545V1.84672C14.5239 1.49485 14.2602 1.23113 13.9083 1.23113L13.9083 1.23117ZM12.4719 2.66754C12.8118 2.66754 13.0875 2.94328 13.0875 3.28313C13.0875 3.62297 12.8118 3.89871 12.4719 3.89871H3.44331C3.10346 3.89871 2.82772 3.62297 2.82772 3.28313C2.82772 2.94328 3.10346 2.66754 3.44331 2.66754H12.4719ZM12.4719 5.33508C12.8118 5.33508 13.0875 5.61082 13.0875 5.95067C13.0875 6.29051 12.8118 6.56626 12.4719 6.56626H3.44331C3.10346 6.56626 2.82772 6.29051 2.82772 5.95067C2.82772 5.61082 3.10346 5.33508 3.44331 5.33508H12.4719ZM12.4719 8.00262C12.8118 8.00262 13.0875 8.27837 13.0875 8.61821C13.0875 8.95806 12.8118 9.2338 12.4719 9.2338H3.44331C3.10346 9.2338 2.82772 8.95806 2.82772 8.61821C2.82772 8.27837 3.10346 8.00262 3.44331 8.00262H12.4719Z" fill={isActive ? "#1D3565" : "#BDBDBD"}/>
    </g>
    <defs>
      <clipPath id="clip0_3128_18247">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}