export default function IconYoute() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="14" height="14" rx="1" fill="#FF0000" />
            <path
                d="M2.51855 11.123V11.1243L2.55702 11.2285C2.5526 11.2164 2.53831 11.1795 2.51855 11.123Z"
                fill="#69C9D0"
            />
            <path
                d="M5.71436 10.6309L10.4521 7.88672L5.71436 5.14258V10.6309Z"
                fill="white"
            />
        </svg>
    );
}
