export function IconTopic() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00002 0.666748L14.3334 4.33341V11.6667L8.00002 15.3334L1.66669 11.6667V4.33341L8.00002 0.666748ZM8.00002 2.20741L3.00002 5.10215V10.898L8.00002 13.7927L13 10.898V5.10215L8.00002 2.20741ZM8.00002 10.6667C6.52726 10.6667 5.33335 9.47281 5.33335 8.00008C5.33335 6.52732 6.52726 5.33342 8.00002 5.33342C9.47275 5.33342 10.6667 6.52732 10.6667 8.00008C10.6667 9.47281 9.47275 10.6667 8.00002 10.6667ZM8.00002 9.33342C8.73642 9.33342 9.33335 8.73648 9.33335 8.00008C9.33335 7.26368 8.73642 6.66675 8.00002 6.66675C7.26362 6.66675 6.66669 7.26368 6.66669 8.00008C6.66669 8.73648 7.26362 9.33342 8.00002 9.33342Z"
        fill="black"
      />
    </svg>
  );
}
export function IconExplain() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99998 14.6666ZM7.99998 13.3333C10.9455 13.3333 13.3333 10.9455 13.3333 7.99992C13.3333 5.0544 10.9455 2.66659 7.99998 2.66659C5.05446 2.66659 2.66665 5.0544 2.66665 7.99992C2.66665 10.9455 5.05446 13.3333 7.99998 13.3333ZM7.33331 9.99992H8.66665V11.3333H7.33331V9.99992ZM8.66665 8.90332V9.33325H7.33331V8.33325C7.33331 7.96505 7.63178 7.66658 7.99998 7.66658C8.55225 7.66658 8.99998 7.21885 8.99998 6.66658C8.99998 6.1143 8.55225 5.66658 7.99998 5.66658C7.51485 5.66658 7.11038 6.01207 7.01918 6.47043L5.71152 6.20889C5.92423 5.13938 6.86798 4.33325 7.99998 4.33325C9.28865 4.33325 10.3333 5.37792 10.3333 6.66658C10.3333 7.72359 9.63045 8.61645 8.66665 8.90332Z"
        fill="black"
      />
    </svg>
  );
}

export function IconReply() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 1.33325C4.33334 1.33325 1.33334 4.33325 1.33334 7.99992C1.33334 9.53325 1.86668 10.9999 2.86668 12.1999L1.53334 13.5333C1.26668 13.7999 1.26668 14.1999 1.53334 14.4666C1.66668 14.5999 1.80001 14.6666 2.00001 14.6666H8.00001C11.6667 14.6666 14.6667 11.6666 14.6667 7.99992C14.6667 4.33325 11.6667 1.33325 8.00001 1.33325ZM5.33334 8.66658C4.93334 8.66658 4.66668 8.39992 4.66668 7.99992C4.66668 7.59992 4.93334 7.33325 5.33334 7.33325C5.73334 7.33325 6.00001 7.59992 6.00001 7.99992C6.00001 8.39992 5.73334 8.66658 5.33334 8.66658ZM8.00001 8.66658C7.60001 8.66658 7.33334 8.39992 7.33334 7.99992C7.33334 7.59992 7.60001 7.33325 8.00001 7.33325C8.40001 7.33325 8.66668 7.59992 8.66668 7.99992C8.66668 8.39992 8.40001 8.66658 8.00001 8.66658ZM10.6667 8.66658C10.2667 8.66658 10 8.39992 10 7.99992C10 7.59992 10.2667 7.33325 10.6667 7.33325C11.0667 7.33325 11.3333 7.59992 11.3333 7.99992C11.3333 8.39992 11.0667 8.66658 10.6667 8.66658Z"
        fill="url(#paint0_linear_3137_22047)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3137_22047"
          x1="16.4219"
          y1="12.0851"
          x2="9.98536"
          y2="-1.35637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142243" />
          <stop offset="0.5182" stopColor="#14225B" />
          <stop offset="0.5778" stopColor="#14225E" />
          <stop offset="1" stopColor="#F03989" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function IconPlus() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#45BEF4" />
      <path
        d="M15.1667 15.1667V10.1667H16.8334V15.1667H21.8334V16.8334H16.8334V21.8334H15.1667V16.8334H10.1667V15.1667H15.1667Z"
        fill="white"
      />
    </svg>
  );
}

export function IconTrash() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 4.66658V13.9999C13.3333 14.3681 13.0348 14.6666 12.6666 14.6666H3.33331C2.96513 14.6666 2.66665 14.3681 2.66665 13.9999V4.66658H1.33331V3.33325H14.6666V4.66658H13.3333ZM3.99998 4.66658V13.3333H12V4.66658H3.99998ZM4.66665 1.33325H11.3333V2.66659H4.66665V1.33325ZM7.33331 6.66658H8.66665V11.3333H7.33331V6.66658Z"
        fill="#E11D48"
      />
    </svg>
  );
}

export function IconQuestion() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99998 14.6666ZM7.99998 13.3333C10.9455 13.3333 13.3333 10.9455 13.3333 7.99992C13.3333 5.0544 10.9455 2.66659 7.99998 2.66659C5.05446 2.66659 2.66665 5.0544 2.66665 7.99992C2.66665 10.9455 5.05446 13.3333 7.99998 13.3333ZM7.33331 9.99992H8.66665V11.3333H7.33331V9.99992ZM8.66665 8.90332V9.33325H7.33331V8.33325C7.33331 7.96505 7.63178 7.66658 7.99998 7.66658C8.55225 7.66658 8.99998 7.21885 8.99998 6.66658C8.99998 6.1143 8.55225 5.66658 7.99998 5.66658C7.51485 5.66658 7.11038 6.01207 7.01918 6.47043L5.71152 6.20889C5.92423 5.13938 6.86798 4.33325 7.99998 4.33325C9.28865 4.33325 10.3333 5.37792 10.3333 6.66658C10.3333 7.72359 9.63045 8.61645 8.66665 8.90332Z"
        fill="black"
      />
    </svg>
  );
}

export function IconEdit() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.02656 11.75C4.05781 11.75 4.08906 11.7469 4.12031 11.7422L6.74844 11.2812C6.77969 11.275 6.80938 11.2609 6.83125 11.2375L13.4547 4.61406C13.4692 4.59961 13.4807 4.58244 13.4885 4.56354C13.4963 4.54463 13.5004 4.52437 13.5004 4.50391C13.5004 4.48344 13.4963 4.46318 13.4885 4.44428C13.4807 4.42538 13.4692 4.40821 13.4547 4.39375L10.8578 1.79531C10.8281 1.76563 10.7891 1.75 10.7469 1.75C10.7047 1.75 10.6656 1.76563 10.6359 1.79531L4.0125 8.41875C3.98906 8.44219 3.975 8.47031 3.96875 8.50156L3.50781 11.1297C3.49261 11.2134 3.49804 11.2995 3.52364 11.3807C3.54923 11.4618 3.59421 11.5355 3.65469 11.5953C3.75781 11.6953 3.8875 11.75 4.02656 11.75ZM5.07969 9.025L10.7469 3.35938L11.8922 4.50469L6.225 10.1703L4.83594 10.4156L5.07969 9.025ZM13.75 13.0625H2.25C1.97344 13.0625 1.75 13.2859 1.75 13.5625V14.125C1.75 14.1938 1.80625 14.25 1.875 14.25H14.125C14.1938 14.25 14.25 14.1938 14.25 14.125V13.5625C14.25 13.2859 14.0266 13.0625 13.75 13.0625Z"
        fill="green"
      />
    </svg>
  );
}

export function IconContentKeyword() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 16.875L8.4 14.25H4.5C4.08579 14.25 3.75 13.9142 3.75 13.5V5.32692C3.75 4.91271 4.08579 4.57692 4.5 4.57692H16.5C16.9142 4.57692 17.25 4.91271 17.25 5.32692V13.5C17.25 13.9142 16.9142 14.25 16.5 14.25H12.6L10.5 16.875ZM11.879 12.75H15.75V6.07692H5.25V12.75H8.4H9.12097L10.5 14.4738L11.879 12.75ZM1.5 1.5H14.25V3H2.25V11.25H0.75V2.25C0.75 1.83579 1.08579 1.5 1.5 1.5Z"
        fill="#2C4B94"
      />
    </svg>
  );
}
