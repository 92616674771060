export default function IconInstagram() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="1"
        fill="url(#paint0_radial_652_1486)"
      />
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="7"
        fill="url(#paint1_radial_652_1486)"
      />
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="7"
        fill="url(#paint2_radial_652_1486)"
      />
      <path
        d="M11.15 5.525C11.15 5.89779 10.8478 6.2 10.475 6.2C10.1022 6.2 9.8 5.89779 9.8 5.525C9.8 5.15221 10.1022 4.85 10.475 4.85C10.8478 4.85 11.15 5.15221 11.15 5.525Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 10.25C9.24264 10.25 10.25 9.24264 10.25 8C10.25 6.75736 9.24264 5.75 8 5.75C6.75736 5.75 5.75 6.75736 5.75 8C5.75 9.24264 6.75736 10.25 8 10.25ZM8 9.35C8.74558 9.35 9.35 8.74558 9.35 8C9.35 7.25442 8.74558 6.65 8 6.65C7.25442 6.65 6.65 7.25442 6.65 8C6.65 8.74558 7.25442 9.35 8 9.35Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 7.82C3.5 6.30786 3.5 5.55179 3.79428 4.97423C4.05314 4.46619 4.46619 4.05314 4.97423 3.79428C5.55179 3.5 6.30786 3.5 7.82 3.5H8.18C9.69214 3.5 10.4482 3.5 11.0258 3.79428C11.5338 4.05314 11.9469 4.46619 12.2057 4.97423C12.5 5.55179 12.5 6.30786 12.5 7.82V8.18C12.5 9.69214 12.5 10.4482 12.2057 11.0258C11.9469 11.5338 11.5338 11.9469 11.0258 12.2057C10.4482 12.5 9.69214 12.5 8.18 12.5H7.82C6.30786 12.5 5.55179 12.5 4.97423 12.2057C4.46619 11.9469 4.05314 11.5338 3.79428 11.0258C3.5 10.4482 3.5 9.69214 3.5 8.18V7.82ZM7.82 4.4H8.18C8.95092 4.4 9.47499 4.4007 9.88007 4.4338C10.2746 4.46603 10.4764 4.52447 10.6172 4.59619C10.9559 4.76876 11.2312 5.04413 11.4038 5.38282C11.4755 5.52358 11.534 5.72536 11.5662 6.11993C11.5993 6.52501 11.6 7.04908 11.6 7.82V8.18C11.6 8.95092 11.5993 9.47499 11.5662 9.88007C11.534 10.2746 11.4755 10.4764 11.4038 10.6172C11.2312 10.9559 10.9559 11.2312 10.6172 11.4038C10.4764 11.4755 10.2746 11.534 9.88007 11.5662C9.47499 11.5993 8.95092 11.6 8.18 11.6H7.82C7.04908 11.6 6.52501 11.5993 6.11993 11.5662C5.72536 11.534 5.52358 11.4755 5.38282 11.4038C5.04413 11.2312 4.76876 10.9559 4.59619 10.6172C4.52447 10.4764 4.46603 10.2746 4.4338 9.88007C4.4007 9.47499 4.4 8.95092 4.4 8.18V7.82C4.4 7.04908 4.4007 6.52501 4.4338 6.11993C4.46603 5.72536 4.52447 5.52358 4.59619 5.38282C4.76876 5.04413 5.04413 4.76876 5.38282 4.59619C5.52358 4.52447 5.72536 4.46603 6.11993 4.4338C6.52501 4.4007 7.04908 4.4 7.82 4.4Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_652_1486"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6 11.5) rotate(-55.3758) scale(12.7598)"
        >
          <stop stop-color="#B13589" />
          <stop offset="0.79309" stop-color="#C62F94" />
          <stop offset="1" stop-color="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_652_1486"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.5 15.5) rotate(-65.1363) scale(11.2971)"
        >
          <stop stop-color="#E0E8B7" />
          <stop offset="0.444662" stop-color="#FB8A2E" />
          <stop offset="0.71474" stop-color="#E2425C" />
          <stop offset="1" stop-color="#E2425C" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_652_1486"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.250001 1.5) rotate(-8.1301) scale(19.4454 4.15918)"
        >
          <stop offset="0.156701" stop-color="#406ADC" />
          <stop offset="0.467799" stop-color="#6A45BE" />
          <stop offset="1" stop-color="#6A45BE" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
