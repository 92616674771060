import { Select, Switch, Popover, Button, Input, Form, Tooltip } from "antd";
import { ItemHeader } from "./ItemHeader";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { useTranslation } from "react-i18next";
import { IconContentKeyword } from "../../../assets/svg/ConversationScript/IconConersationScript";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { UploadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  KEY_OPTIONS_KEYWORDS,
  TYPE_CONTENT_CONTAIN_AND_NO_CONTAIN_KEY_WORD,
  TYPE_CONTENT_CONTAIN_KEY_WORD,
  TYPE_CONTENT_IMAGE_AND_CONTAIN_KEY_WORD,
  TYPE_CONTENT_IMAGE_AND_NO_CONTAIN_KEY_WORD,
  TYPE_CONTENT_LINK_AND_CONTAIN_KEY_WORD,
  TYPE_CONTENT_LINK_AND_NO_CONTAIN_KEY_WORD,
  TYPE_CONTENT_NO_CONTAIN_KEY_WORD,
  TYPE_CONTENT_PHONE_AND_CONTAIN_KEY_WORD,
  TYPE_CONTENT_PHONE_AND_NO_CONTAIN_KEY_WORD,
} from "../../../utils/constants/common";
export const KeywordSetting = ({
  itemSelectedToEdit,
  setData,
}: {
  itemSelectedToEdit?: ICreateAutomaticScript | IUpdateAutomaticScrip | any;
  setData: any;
}) => {
  const { t } = useTranslation();

  const LABEl_KEY_WORD_BY_KEY: any = {
    CONTAIN: t("keyword_setting.contain"),
    NOT_CONTAIN: t("keyword_setting.not_contain"),
    PHONE_NUMBER: t("keyword_setting.phone_number"),
    IMAGE: t("keyword_setting.image"),
    VIDEO: t("keyword_setting.video"),
    LINK: t("keyword_setting.link"),
  };

  const initState = {
    type: null,
    contain_key_words: [],
    no_contain_key_words: [],
    is_active: 0,
  };

  const [isSelectFocussed, setIsSelectFocussed] = useState<any>(0);

  useEffect(() => {
    if (!itemSelectedToEdit) {
      setData((preState: any) => {
        return {
          ...preState,
          key_word_setting: initState,
        };
      });
    }
  }, []);

  const [openSettingKeyword, setOpenSettingKeyword] = useState(
    itemSelectedToEdit?.key_word_setting?.is_active === 1
  );

  const handleDeselected = async (value: any, keyLabel: any) => {
    setData((preState: any) => {
      return {
        ...preState,
        key_word_setting: {
          ...(preState.key_word_setting || {}),
          contain_key_words:
            keyLabel === "CONTAIN"
              ? (preState.key_word_setting?.contain_key_words || []).filter(
                  (key: string) => key != value
                )
              : preState.key_word_setting.contain_key_words,
          no_contain_key_words:
            keyLabel === "NOT_CONTAIN"
              ? (preState.key_word_setting?.no_contain_key_words || []).filter(
                  (key: string) => key != value
                )
              : preState.key_word_setting?.no_contain_key_words,
          is_active: openSettingKeyword ? 1 : 0,
        },
      };
    });
  };

  const onKeydown = (value: any, keyLabel?: string) => {
    setData((preState: any) => {
      return {
        ...preState,
        key_word_setting: {
          ...(preState.key_word_setting || {}),
          contain_key_words:
            keyLabel === "CONTAIN"
              ? [
                  ...(preState.key_word_setting?.contain_key_words || []),
                  value,
                ].filter(
                  (value, index, array) => array.indexOf(value) === index
                )
              : preState.key_word_setting.contain_key_words,
          no_contain_key_words:
            keyLabel === "NOT_CONTAIN"
              ? [
                  ...(preState.key_word_setting?.no_contain_key_words || []),
                  value,
                ].filter(
                  (value, index, array) => array.indexOf(value) === index
                )
              : preState.key_word_setting?.no_contain_key_words,
          is_active: openSettingKeyword ? 1 : 0,
        },
      };
    });
  };

  const OPTION_KEYS: any = {
    [t("keyword_setting.contain").toLowerCase()]: "CONTAIN",
    [t("keyword_setting.not_contain").toLowerCase()]: "NOT_CONTAIN",
  };

  const optionTypes = [
    {
      label: t("keyword_setting.content"),
      title: "Nội dung",
      options: [
        {
          label: t("keyword_setting.contain"),
          value: 1,
        },
        {
          label: t("keyword_setting.not_contain"),
          value: 2,
        },
        {
          label: t("keyword_setting.contain_and_not_contain"),
          value: 3,
        },
      ],
    },

    {
      label: t("keyword_setting.content_is"),
      title: "Nội dung là",
      options: [
        {
          label: t("keyword_setting.image"),
          value: 4,
        },
        {
          label: t("keyword_setting.video"),
          value: 5,
        },
        {
          label: t("keyword_setting.link"),
          value: 6,
        },
      ],
    },

    {
      label: t("keyword_setting.content_has"),
      title: t("keyword_setting.content_has"),
      options: [
        {
          label: t("keyword_setting.phone_number"),
          value: 7,
        },
        {
          label: t("keyword_setting.phone_number_and_contain"),
          value: 8,
        },
        {
          label: t("keyword_setting.phone_number_and_not_contain"),
          value: 9,
        },

        {
          label: t("keyword_setting.link_and_contain"),
          value: 10,
        },
        {
          label: t("keyword_setting.link_and_not_contain"),
          value: 11,
        },
        {
          label: t("keyword_setting.image_and_contain"),
          value: 12,
        },
        {
          label: t("keyword_setting.image_and_not_contain"),
          value: 13,
        },
      ],
    },
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      handleFile(files[0]);
    }
  };

  const handleFile = (file: any) => {
    try {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e: any) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data: any[] = XLSX.utils.sheet_to_json(ws);

        const data1 = [];
        const data2 = [];

        let keyInput: any = [];

        for (const item of data) {
          const keys = Object.keys(item);
          keyInput = [...keyInput, ...keys].filter(
            (value, index, array) => array.indexOf(value) === index
          );
          data1.push(item[keys[0]]);
          data2.push(item[keys[1]]);
        }

        const dataInSheet: any = {
          [OPTION_KEYS[keyInput[0].trim().toLowerCase() || ""]]: data1,
          [OPTION_KEYS[keyInput[1].trim().toLowerCase() || ""]]: data2,
        };

        const optionInSheet = Object.keys(dataInSheet);

        let dataKeyWord: any = {};
        if (
          optionInSheet.includes(
            KEY_OPTIONS_KEYWORDS[TYPE_CONTENT_CONTAIN_KEY_WORD]
          )
        ) {
          dataKeyWord.type = TYPE_CONTENT_CONTAIN_KEY_WORD;
          dataKeyWord.contain_key_words = dataInSheet[
            KEY_OPTIONS_KEYWORDS[TYPE_CONTENT_CONTAIN_KEY_WORD]
          ].filter((keyword: string) => keyword !== undefined);
        }
        if (
          optionInSheet.includes(
            KEY_OPTIONS_KEYWORDS[TYPE_CONTENT_NO_CONTAIN_KEY_WORD]
          )
        ) {
          dataKeyWord.type = TYPE_CONTENT_NO_CONTAIN_KEY_WORD;
          dataKeyWord.no_contain_key_words = dataInSheet[
            KEY_OPTIONS_KEYWORDS[TYPE_CONTENT_NO_CONTAIN_KEY_WORD]
          ].filter((keyword: string) => keyword !== undefined);
        }
        if (
          optionInSheet.includes(
            KEY_OPTIONS_KEYWORDS[TYPE_CONTENT_CONTAIN_KEY_WORD]
          ) &&
          optionInSheet.includes(
            KEY_OPTIONS_KEYWORDS[TYPE_CONTENT_NO_CONTAIN_KEY_WORD]
          )
        ) {
          dataKeyWord.type = TYPE_CONTENT_CONTAIN_AND_NO_CONTAIN_KEY_WORD;
        }

        setData((preState: any) => {
          return {
            ...preState,
            key_word_setting: {
              ...dataKeyWord,
              is_active: 1,
            },
          };
        });
      };

      if (file) {
        if (rABS) {
          reader.readAsBinaryString(file);
        } else {
          reader.readAsArrayBuffer(file);
        }
      }

      setOpenSettingKeyword(true);
    } catch (e) {
      console.log("Empty!");
    }
  };

  const KeyWorkInputComponent = ({
    keyOption,
    type,
  }: {
    keyOption: string;
    type: number;
  }) => {
    const keySelect = keyOption?.split("_AND_");

    let optionsDetail: any[] = [];

    optionTypes.map((option) => {
      optionsDetail = [...optionsDetail, ...option.options];
    });

    return (
      <div>
        {keySelect.map((key, index) => {
          return (
            <div>
              {[
                TYPE_CONTENT_CONTAIN_AND_NO_CONTAIN_KEY_WORD,
                TYPE_CONTENT_PHONE_AND_CONTAIN_KEY_WORD,
                TYPE_CONTENT_PHONE_AND_NO_CONTAIN_KEY_WORD,
                TYPE_CONTENT_LINK_AND_CONTAIN_KEY_WORD,
                TYPE_CONTENT_LINK_AND_NO_CONTAIN_KEY_WORD,
                TYPE_CONTENT_IMAGE_AND_CONTAIN_KEY_WORD,
                TYPE_CONTENT_IMAGE_AND_NO_CONTAIN_KEY_WORD,
              ].includes(type) && (
                <>
                  {" "}
                  <div className={`pb-1 `}>{LABEl_KEY_WORD_BY_KEY[key]}</div>
                </>
              )}

              <div>
                <Select
                  key={key}
                  mode="tags"
                  size="middle"
                  // placeholder="Nhập từ khoá"
                  style={{ width: "100%" }}
                  filterOption={false}
                  open={false}
                  onDeselect={(e) => handleDeselected(e, key)}
                  defaultValue={
                    itemSelectedToEdit?.key_word_setting.type &&
                    [
                      TYPE_CONTENT_CONTAIN_KEY_WORD,
                      TYPE_CONTENT_NO_CONTAIN_KEY_WORD,
                      TYPE_CONTENT_CONTAIN_AND_NO_CONTAIN_KEY_WORD,
                      TYPE_CONTENT_PHONE_AND_CONTAIN_KEY_WORD,
                      TYPE_CONTENT_PHONE_AND_NO_CONTAIN_KEY_WORD,
                      TYPE_CONTENT_LINK_AND_CONTAIN_KEY_WORD,
                      TYPE_CONTENT_LINK_AND_NO_CONTAIN_KEY_WORD,
                      TYPE_CONTENT_IMAGE_AND_CONTAIN_KEY_WORD,
                      TYPE_CONTENT_IMAGE_AND_NO_CONTAIN_KEY_WORD,
                    ].includes(itemSelectedToEdit?.key_word_setting.type)
                      ? itemSelectedToEdit?.key_word_setting[
                          key === "CONTAIN"
                            ? "contain_key_words"
                            : key === "NOT_CONTAIN"
                            ? "no_contain_key_words"
                            : optionsDetail.find((o) => o.value === type)?.label
                        ]
                      : []
                  }
                  tokenSeparators={[","]}
                  value={
                    itemSelectedToEdit?.key_word_setting.type
                      ? itemSelectedToEdit?.key_word_setting[
                          key === "CONTAIN"
                            ? "contain_key_words"
                            : key === "NOT_CONTAIN"
                            ? "no_contain_key_words"
                            : optionsDetail.find((o) => o.value === type)?.label
                        ]
                      : []
                  }
                  onSelect={(e) => onKeydown(e, key)}
                  showAction={["focus", "click"]}
                  placeholder={
                    keySelect[0].includes("CONTAIN") || !keyOption
                      ? t("keyword_setting.type_keyword")
                      : LABEl_KEY_WORD_BY_KEY[key]
                  }
                  disabled={
                    keyOption !== "" &&
                    !["CONTAIN", "NOT_CONTAIN"].includes(key)
                  }
                  onFocus={() => setIsSelectFocussed(index)}
                  onBlur={() => setIsSelectFocussed(null)}
                  autoFocus={isSelectFocussed === index}
                  allowClear={isSelectFocussed === index}
                  onClear={() => {
                    setData((preState: any) => {
                      return {
                        ...preState,
                        key_word_setting: {
                          ...(preState.key_word_setting || {}),
                          contain_key_words:
                            key === "CONTAIN"
                              ? []
                              : preState.key_word_setting.contain_key_words,
                          no_contain_key_words:
                            key === "NOT_CONTAIN"
                              ? []
                              : preState.key_word_setting?.no_contain_key_words,
                          is_active: openSettingKeyword ? 1 : 0,
                        },
                      };
                    });
                  }}
                />
                {/* </Form.Item> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className={`flex p-4 bg-gray-100 justify-between items-center gap-2 rounded-t-lg`}
      >
        <div className="flex items-center gap-2 ">
          <ItemHeader title={t("keyword_setting.keyword_setting")} />
        </div>
        <div className="pl-8 flex items-center">
          <div className="flex items-center  ">
            <UploadOutlined />
            <span className="pr-6 pl-2">
              {" "}
              {t("keyword_setting.upload_keywords")}
            </span>
            {/* {!openSettingKeyword && ( */}
            <Input
              accept=".xlsx"
              type="file"
              className="opacity-0 absolute  w-[120px] file-input"
              onChange={handleFileChange}
              size="small"
              // style={{ cursor: "pointer" }}

              // width={"30px"}
            />
            {/* )} */}
          </div>
          <div>
            <Switch
              checked={openSettingKeyword}
              defaultChecked={openSettingKeyword}
              onChange={() => {
                setOpenSettingKeyword(!openSettingKeyword);
                setData((preState: any) => {
                  return {
                    ...preState,
                    key_word_setting: {
                      ...preState.key_word_setting,
                      type: itemSelectedToEdit.key_word_setting.type
                        ? itemSelectedToEdit.key_word_setting.type
                        : 1,
                      is_active: !openSettingKeyword ? 1 : 0,
                    },
                  };
                });
              }}
            />
          </div>
          <Tooltip
            title={
              <h6>
                {t("keyword_setting.reference")}{" "}
                <a
                  className="italic font-bold"
                  target="_blank"
                  href={
                    localStorage.getItem("chatLang") === "vi"
                      ? "https://docs.google.com/spreadsheets/d/1luwk6iZvX5fjUf1MvyuQhqJip7CLwGC5sv2BLzZr0vY/edit?gid=0#gid=0"
                      : "https://docs.google.com/spreadsheets/d/1luwk6iZvX5fjUf1MvyuQhqJip7CLwGC5sv2BLzZr0vY/edit?gid=666102815#gid=666102815"
                  }
                >
                  {t("keyword_setting.sample")}
                </a>{" "}
              </h6>
            }
            key={"auto_send_message_comment"}
          >
            <div className="ml-4 flex items-center cursor-pointer">
              <InfoCircleOutlined />
            </div>
          </Tooltip>
        </div>
      </div>

      {openSettingKeyword && (
        <div className={`flex p-4 bg-white rounded-b-lg justify-between  `}>
          <Popover trigger="hover" className="w-[20%]">
            <Button style={{ borderColor: "#1677ff", cursor: "auto" }}>
              <div className="flex items-center">
                <IconContentKeyword />
                <span className="pl-2"> {t("keyword_setting.if_content")}</span>
              </div>
            </Button>
          </Popover>

          <div className="w-[30%] items-center">
            <Select
              defaultValue={itemSelectedToEdit?.key_word_setting?.type || 1}
              // style={{ width: 220 }}
              className="w-[100%]"
              value={itemSelectedToEdit?.key_word_setting?.type || 1}
              onChange={(e: any) => {
                if (e !== itemSelectedToEdit?.key_word_setting?.type) {
                  setData((preState: any) => {
                    return {
                      ...preState,
                      key_word_setting: {
                        type: e,
                        contain_key_words:
                          preState.key_word_setting.contain_key_words,
                        no_contain_key_words:
                          preState.key_word_setting.no_contain_key_words,
                        is_active: openSettingKeyword ? 1 : 0,
                      },
                    };
                  });
                }
              }}
              // value={dataKeyWords.option}
              options={optionTypes}
            />
          </div>

          <div>{t("keyword_setting.keywords")}</div>

          <div className="w-[40%]">
            <KeyWorkInputComponent
              keyOption={
                KEY_OPTIONS_KEYWORDS[
                  (itemSelectedToEdit?.key_word_setting?.type as
                    | 1
                    | 2
                    | 3
                    | 4
                    | 5
                    | 6
                    | 7
                    | 8
                    | 9) || 1
                ]
              }
              type={itemSelectedToEdit?.key_word_setting?.type || 1}
            />
          </div>
        </div>
      )}
    </>
  );
};
