import { Avatar, Button, Checkbox, Col, Dropdown, Input, List, MenuProps, Pagination, Row, Spin, Switch, Tooltip } from "antd";
import "./style.css";
import style from "./index.module.scss";
import { IconPlatform } from "../shared/IconPlatform";
import { PLATFORM } from "../../utils/constants/platform";
import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { getListChannels, getListChannelsTotal, updateChannelWebHook } from "../../api/channel";
import { getPlatformLabel, getUserInfor } from "../../helper/common";
import CustomImg from "../shared/CustomImg";
import useQuery from "../../hooks/useQuery";

const getPlatformIconLabel = (platform: string) => {
    switch (platform) {
        case "all":
            return PLATFORM.NULL
        case "facebook":
            return PLATFORM.PLATFORM_FACEBOOK;
        case "instagram":
            return PLATFORM.PLATFORM_INSTAGRAM;
        case "tiktok":
            return PLATFORM.PLATFORM_TIKTOK;
        case "zalo":
            return PLATFORM.PLATFORM_ZALO_OA;
    }
}

const dropDownItem = (status: any, action: any, t: any) => {
    const items: MenuProps['items'] = [
        {
            label: (<p onClick={() => action("all")} className={`${status === "all" && "text-[#0E88F0]"}`}>{t("channel_view.filter.all")}</p>),
            key: "all"
        },
        {
            label: (<p onClick={() => action("enable")} className={`${status === "enable" && "text-[#0E88F0]"}`}>{t("channel_view.filter.enabled")}</p>),
            key: "enable"
        },
        {
            label: (<p onClick={() => action("disable")} className={`${status === "disable" && "text-[#0E88F0]"}`}>{t("channel_view.filter.disabled")}</p>),
            key: "disable"
        },
    ]

    return { items };
}

export default function ChannelViewType() {
    const { t } = useTranslation();
    const query = useQuery();
    const page = query.get("page") || 1;
    const filters = query.get("filters") || "all";
    const platform = Number(query.get("platform")) || 0;
    const [selectedPlatform, setSelectedPlatform] = useState(platform);
    const [statusFilter, setStatusFilter] = useState(filters);
    const [isLoading, setIsLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [isLoadingPlatform, setIsLoadingPlatform] = useState(false);
    const [listPlatform, setListPlatform] = useState<any[]>([]);
    const [pageinationData, setPageinationData] = useState<any>({
        currentPage: page,
        pageSize: 0,
        total: 0
    });
    const scrollContainerRef = useRef<HTMLUListElement | null>(null);
    const useInfor = getUserInfor();

    const getListChannel = async (page: number, platform: any) => {
        setIsLoading(true);
        const params = {
            platform: platform,
            ...(statusFilter !== "all" && {
                is_sync_chat: statusFilter === "enable" ? 1 : 0,
                is_sync_comment: statusFilter === "enable" ? 1 : 0
            })
        };
        await getListChannels(useInfor.last_project_active, page, params).then((res) => {
            if (res.status) {
                setListData(res.data.data);
                setPageinationData({
                    currentPage: res.data?.current_page,
                    pageSize: res.data?.per_page,
                    total: res.data?.total,
                })
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const getPlatformTotal = async () => {
        setIsLoadingPlatform(true);
        await getListChannelsTotal(useInfor.last_project_active, {}).then((res) => {
            if (res.status) {
                setListPlatform(Object.entries(res.data).map((item) => ({
                    title: item[0].toLocaleLowerCase(),
                    icon: getPlatformIconLabel(item[0].toLowerCase()),
                    data: item[1]
                })))
            }
        })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsLoadingPlatform(false);
            })
    }

    const updateWebhook = async (item: any) => {
        const data = {
            is_sync_chat: !item.setting_sync_webhook.is_sync_chat,
            is_sync_comment: !item.setting_sync_webhook.is_sync_comment
        }
        await updateChannelWebHook(item.project_id, item.setting_sync_webhook._id, data).then((res) => {
            if (res.status) {
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getPlatformTotal();
    }, [(useInfor.last_project_active)]);

    useEffect(() => {
        getListChannel(1, selectedPlatform);
    }, [selectedPlatform, statusFilter]);

    useEffect(() => {
        const url = new URL(window.location.href);

        if(statusFilter !== "all") {
            url.searchParams.set('filters', statusFilter);
        } else {
            url.searchParams.delete('filters');
        }

        if(selectedPlatform) {
            url.searchParams.set('platform', selectedPlatform.toString());
        } else {
            url.searchParams.delete('platform');
        }

        url.searchParams.set('page', pageinationData.currentPage.toString());

        window.history.pushState({}, '', url.toString());

    }, [selectedPlatform, statusFilter, pageinationData.current_page]);

    const scroll = useCallback((scrollOffset: any) => {
        if (scrollContainerRef.current) {
            const newScrollPosition = scrollContainerRef.current.scrollLeft + scrollOffset;
            scrollContainerRef.current.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth'
            });
        }
    }, []);

    const changePlatform = (itemCurrent: any) => {
        setSelectedPlatform(itemCurrent.icon);
    }

    const changeCheckWebhook = async (item: any) => {
        setListData((prev: any) => prev.map((itemPrev: any) => {
            if (itemPrev._id === item._id) {
                return {
                    ...itemPrev,
                    setting_sync_webhook: {
                        ...itemPrev.setting_sync_webhook,
                        is_sync_chat: !item.setting_sync_webhook.is_sync_chat,
                        is_sync_comment: !item.setting_sync_webhook.is_sync_comment
                    }
                }
            }

            return itemPrev;
        }));
        await updateWebhook(item);
    }

    const renderTopBlock = () => {
        return (<>
            <div className="p-5 bg-white rounded">
                <p className="mb-[12px] text-[#1F2937] text-[14px] font-bold leading-[16px]">{t("channel_view.box_1.header")}</p>
                <Row className="justify-between">
                    <Col xs={24} md={9} className="mb-2 sm:mb-0">
                        <Input
                            placeholder="Textbox"
                            className="border-white "
                            prefix={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                    fill="#D1D5DB"
                                >
                                    <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"></path>
                                </svg>
                            }
                        />
                    </Col>
                    <Col xs={24} md={7} className="flex gap-2.5 justify-end">
                        <Dropdown menu={dropDownItem(statusFilter, setStatusFilter, t)} trigger={["click"]}>
                            <Button
                                className="flex items-center text-[#2C4B94] border-[#2C4B94]"
                                color="primary"
                                icon={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                    >
                                        <path d="M14 14V20L10 22V14L4 5V3H20V5L14 14ZM6.4037 5L12 13.3944L17.5963 5H6.4037Z"></path>
                                    </svg>
                                }
                            >
                                {t("channel_view.box_1.btn_filter")}
                            </Button>
                        </Dropdown>
                        {/* <Button
                            className="flex items-center text-[#2C4B94] border-[#2C4B94]"
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path d="M13 18V20H19V22H13C11.8954 22 11 21.1046 11 20V18H8C5.79086 18 4 16.2091 4 14V10H20V14C20 16.2091 18.2091 18 16 18H13ZM16 6H19C19.5523 6 20 6.44772 20 7V9H4V7C4 6.44772 4.44772 6 5 6H8V2H10V6H14V2H16V6ZM12 14.5C12.5523 14.5 13 14.0523 13 13.5C13 12.9477 12.5523 12.5 12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5Z"></path>
                                </svg>
                            }
                            onClick={handleConnectChannel}
                        >
                            {t("channel_view.box_1.btn_connect_channel")}
                        </Button> */}
                    </Col>
                </Row>
            </div>
        </>)
    }

    const renderPlatformBlocks = () => {
        return (
            <div className={style.platformListContainer}>
                <Button
                    icon={<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="currentColor">
                        <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                    </svg>}
                    onClick={() => scroll(-300)}
                    className={`cursor-pointer border-none ${style.btn_scroll}`}
                />
                {
                    isLoadingPlatform ? <div className="flex justify-center w-full">
                        <Spin />
                    </div> : <ul className={style.listPlatform} ref={scrollContainerRef}>{renderListPlatform()}</ul>
                }
                <Button
                    icon={<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="currentColor">
                        <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                    </svg>}
                    onClick={() => scroll(300)}
                    className={`cursor-pointer border-none ${style.btn_scroll}`}
                />
                {/* <Button
                    className={style.btn_selectChannel}
                    icon={
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="currentColor"><path d="M7.24264 17.9967H3V13.754L14.435 2.319C14.8256 1.92848 15.4587 1.92848 15.8492 2.319L18.6777 5.14743C19.0682 5.53795 19.0682 6.17112 18.6777 6.56164L7.24264 17.9967ZM3 19.9967H21V21.9967H3V19.9967Z"></path></svg>
                    }
                >
                    <span className={style.btn_text}>{t("channel_view.box_2.choose_channel")}</span>
                </Button> */}
            </div>
        )
    }

    const renderListPlatform = () => {
        return listPlatform.map((item: any, index: number) => (
            <li
                key={index}
                className={`${item.icon === selectedPlatform ? "border-[#2C4B94] bg-[#F1F5FF]" : "border-[#EBEAED]"} ${style.platformItem} cursor-pointer`}
                onClick={() => changePlatform(item)}
            >
                <div className="flex items-center gap-2">
                    <IconPlatform platform={item.icon} />
                    <span className="text-xs font-semibold">{t("channel_view.box_2." + item.title)}</span>
                </div>
                <span className={`${item.icon === selectedPlatform ? "bg-[#2C4B94] text-white" : "bg-[#F3F4F6] text-[#4B5563]"} text-[10px] px-2.5 rounded-[10px] font-semibold`}>{item.data}</span>
            </li>
        ));
    };

    const renderListItem = (item: any, index: number) => {
        return (
            <Tooltip title={item.is_error && t("channel_view.tooltips.conenct")}>
                <List.Item>
                    <div key={index} className={`${style.itemContainer} cursor-pointer ${item.is_error ? style.itemContainerError : ''}`}>
                        {item?.platform === PLATFORM.PLATFORM_FACEBOOK || item?.platform === PLATFORM.PLATFORM_FACEBOOK_GROUP ?
                            <CustomImg size={50} className="rounded" src={item?.picture} alt="avatar" /> :
                            <CustomImg size={50} className="rounded-full" src={item?.picture} alt="avatar" />}
                        <div className={style.itemContent}>
                            <div className="flex items-center gap-[5px]">
                                <span className={`${style.itemChannelName} ${item.is_error ? style.itemChannelNameError : ''}`}>{item?.name} {item.is_error && `(Lỗi)`}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <IconPlatform platform={item?.platform} />
                                    <span className={style.itemPlatformLabel}>{getPlatformLabel(item.platform)}</span>
                                </div>
                                <Tooltip title={t("channel_view.tooltips.switch")}>
                                    <div className={`${item.is_error && "cursor-not-allowed"}`}>
                                        <Switch
                                            size="small"
                                            onChange={() => item.is_error ? null : changeCheckWebhook(item)}
                                            className={`${item.is_error && "cursor-not-allowed"}`}
                                            checked={item.setting_sync_webhook.is_sync_chat && item.setting_sync_webhook.is_sync_comment}
                                        />
                                        <span className={style.itemPlatformLabel}>
                                            {item.setting_sync_webhook.is_sync_chat && item.setting_sync_webhook.is_sync_comment ? t("channel_view.switch_enable") : t("channel_view.switch_disable")}
                                        </span>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </List.Item>
            </Tooltip>
        )
    }

    return (
        <div>
            <div className="w-100">
                <div className={style.contentContainer}>
                    {renderTopBlock()}
                    {renderPlatformBlocks()}
                    <div className={style.listItemContainer}>
                        {
                            isLoading ? <div className="flex justify-center pb-4">
                                <Spin size="large" />
                            </div> : (
                                <List
                                    dataSource={listData}
                                    grid={{
                                        gutter: 20,
                                        xs: 1,
                                        sm: 2,
                                        md: 2,
                                        lg: 2,
                                        xl: 3,
                                        xxl: 3,
                                    }}
                                    renderItem={(item, index) => renderListItem(item, index)}
                                />
                            )
                        }
                        <div className="flex justify-end pb-2">
                            <Pagination
                                current={pageinationData.currentPage}
                                pageSize={pageinationData.pageSize}
                                total={pageinationData.total}
                                onChange={(page, pageSize) => {
                                    getListChannel(page, selectedPlatform);
                                }}
                                hideOnSinglePage={true}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
