import ItemContent from "../../shared/ItemContent";
import {IconQuestion} from "../../../assets/svg/ConversationScript/IconConersationScript";
import {Typography} from "antd";
import { useTranslation } from "react-i18next";

export function Note() {
  const {t} = useTranslation();
  return (
    <div className={``} style={{minWidth:"185px"}}>
      <ItemContent childContent={<div>
        <Typography className={`font-semibold text-sm`}>{t('tag.note-title1')}<Typography className={`inline font-normal`}>{t('tag.note-desc1')}</Typography></Typography>

        <Typography className={`font-semibold text-sm mt-4`}>{t('tag.note-title2')}<Typography className={`inline font-normal`}>{t('tag.note-desc2')}</Typography></Typography>
      </div>
      } iconTitle={<IconQuestion/>} title={t('tag.note')}
      />
    </div>
  )
}
