import TextArea from "antd/es/input/TextArea";
import { ItemHeader } from "./ItemHeader";
import { AutoNotes } from "./AutoNotes";
import {
  Button,
  Card,
  Input,
  Radio,
  RadioChangeEvent,
  Typography,
  message,
} from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import IconExpand from "../../../assets/svg/IconExpand";
import IconPlusNoBackground from "../../../assets/svg/IconPlusNoBackground";
import IconLink from "../../../assets/svg/IconLink";
import { IButton } from "../../../dto/automatic-script/response/response";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

enum MESSAGE_TYPE {
  NORMAL = 1,
  WITH_BUTTON = 2,
}

export const MessageContent = ({
  itemSelectedToEdit,
  setData,
}: {
  itemSelectedToEdit?: ICreateAutomaticScript | IUpdateAutomaticScrip;
  setData: Dispatch<
    SetStateAction<ICreateAutomaticScript | IUpdateAutomaticScrip | undefined>
  >;
}) => {
  const { t } = useTranslation();

  const [typeValue, setTypeValue] = useState(
    itemSelectedToEdit?.message?.type || MESSAGE_TYPE.NORMAL
  );

  const [textValue, setTextValue] = useState<string>(
    itemSelectedToEdit?.message?.text || ""
  );

  const handleTextChange = (value: string) => {
    setTextValue(value);
    setData((pre: any) => ({
      ...pre,
      message: {
        ...pre.message,
        text: value,
      },
    }));
  };

  const [currentButtonPreSave, setCurrentButtonPreSave] = useState<IButton[]>(
    itemSelectedToEdit?.message?.buttons || []
  );

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setTypeValue(e.target.value);

    setData((pre: any) => ({
      ...pre,
      message: {
        ...pre.message,
        type: e.target.value,
      },
    }));
  };

  const [currentButton, setCurrentButton] = useState<IButton[]>(
    itemSelectedToEdit?.message?.buttons || []
  );

  const handleAddButton = () => {
    if (currentButtonPreSave.length === 3) {
      message.error(t("campaign.exceed_button"));
    } else {
      setCurrentButtonPreSave((pre: any) => [
        ...pre,
        {
          title:
            t("campaign.button_default") + (currentButtonPreSave.length + 1),
          url: "",
          type: "web_url",
        },
      ]);
    }
  };

  const handleDeleteButton = (index: number) => {
    const newValue = currentButtonPreSave.splice(index, 1);
    setCurrentButtonPreSave(currentButtonPreSave);

    setData((pre: any) => ({
      ...pre,
      message: {
        ...pre.message,
        buttons: newValue,
      },
    }));
  };

  const handleSaveButton = (index: number) => {
    currentButton[index]
      ? (currentButton[index] = currentButtonPreSave[index])
      : currentButton.push(currentButtonPreSave[index]);

    const newValue = JSON.parse(JSON.stringify(currentButton)) as IButton[];

    const newButtonPreSave = currentButtonPreSave.map((cbt, i) => {
      if (newValue.find((v) => v.title === cbt.title)) {
        return cbt;
      } else {
        return {
          title: t("campaign.button_default") + (i + 1),
          url: "",
          type: "web_url",
        };
      }
    });

    setCurrentButtonPreSave(newButtonPreSave);

    setCurrentButton(newValue);

    setData((pre: any) => ({
      ...pre,
      message: {
        ...pre.message,
        buttons: newValue,
      },
    }));
  };

  const SettingButton = ({
    button,
    index,
  }: {
    button: IButton;
    index: number;
  }) => (
    <Card title="Cài đặt nút" bordered={false} style={{ width: 300 }}>
      <Typography className={`text-sm font-semibold`}>
        {t("campaign.title")}
      </Typography>
      <div className="pt-2">
        <Input
          size="large"
          onChange={(e) => {
            // setCurrentButtonSetting({ ...button, title: e.target.value });
            button.title = e.target.value;
          }}
          defaultValue={button.title || ""}
          placeholder={t("campaign.enter_title")}
        />
      </div>

      <div className="pt-3">
        <Typography className={`text-sm font-semibold`}>
          {t("campaign.action_button")}
        </Typography>
      </div>
      <div className="pt-2">
        <Input
          size="large"
          placeholder="Link website"
          disabled
          prefix={<IconLink />}
        />
      </div>

      <div className="pt-3">
        <Typography className={`text-sm font-semibold`}>
          {t("campaign.enter_link")}
        </Typography>
      </div>
      <div className="pt-3">
        <Input
          size="large"
          defaultValue={button.url || ""}
          onChange={(e) => {
            button.url = e.target.value;
          }}
          placeholder={t("campaign.enter_link")}
        />
      </div>

      <div className="flex justify-between pt-3">
        <Button size="large" onClick={() => handleDeleteButton(index)} danger>
          {t("campaign.delete")}
        </Button>
        <Button size="large" onClick={() => handleSaveButton(index)}>
          {t("campaign.save")}
        </Button>
      </div>
    </Card>
  );

  return (
    <div className="w-[49%]">
      <div className={`flex p-4 bg-gray-100 items-center gap-2 rounded-t-lg`}>
        <ItemHeader title={t("campaign.message_title")} />
      </div>
      <div
        className={`p-4 flex gap-y-5 bg-white items-center rounded-b-lg flex-wrap`}
      >
        <Typography className={`text-sm font-semibold`}>
          {t("campaign.message_auto_after_comment")}
        </Typography>

        <div className="items-center w-[100%]">
          <Radio.Group onChange={onChange} value={typeValue}>
            <Radio value={1}> {t("campaign.normal_message")}</Radio>
            <Radio className="pl-2" value={2}>
              {t("campaign.message_with_button")}
            </Radio>
          </Radio.Group>
        </div>

        <div className="flex justify-between w-[100%]">
          <div className="w-[75%]">
            <TextArea
              // className="w-[60%]"
              required 
              placeholder={t("campaign.enter_message")}
              rows={8}
              value={textValue}
              onChange={(e: any) => handleTextChange(e.target.value)}
            />
          </div>
          <div className="w-[25%]">
            <img src="/BotMessageExample.png" />
          </div>
        </div>

        {typeValue === MESSAGE_TYPE.WITH_BUTTON &&
          currentButtonPreSave.map((button: IButton, index: number) => (
            <div className="w-[65%]">
              <Popover
                popupVisible={false}
                placement="rightTop"
                content={<SettingButton button={button} index={index} />}
              >
                <Button className="w-[100%] text-left">
                  <div className="flex items-center">
                    <IconExpand />
                    <div className="pl-3 flex-1">{button.title}</div>
                    <div>
                      <IconLink />
                    </div>
                  </div>
                </Button>
              </Popover>
            </div>
          ))}

        {typeValue === MESSAGE_TYPE.WITH_BUTTON && (
          <div className="w-[65%]">
            <Button onClick={handleAddButton} className="w-[100%] text-left">
              <div className="flex items-center">
                <IconPlusNoBackground />
                <div className="pl-3">{t("campaign.add_button")}</div>
              </div>
            </Button>
          </div>
        )}

        <AutoNotes />
      </div>
    </div>
  );
};
