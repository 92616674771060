export default function IconFilter() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2C9.83667 2 11.6367 2.15467 13.3887 2.452C13.744 2.512 14 2.82267 14 3.18267V3.87867C14 4.07565 13.9612 4.27071 13.8858 4.4527C13.8104 4.63469 13.7 4.80005 13.5607 4.93934L9.93933 8.56067C9.80005 8.69996 9.68956 8.86532 9.61418 9.04731C9.5388 9.2293 9.5 9.42435 9.5 9.62134V11.5727C9.50005 11.8513 9.42249 12.1245 9.27601 12.3615C9.12952 12.5985 8.91991 12.7901 8.67067 12.9147L6.5 14V9.62134C6.5 9.42435 6.4612 9.2293 6.38582 9.04731C6.31044 8.86532 6.19995 8.69996 6.06067 8.56067L2.43933 4.93934C2.30005 4.80005 2.18956 4.63469 2.11418 4.4527C2.0388 4.27071 2 4.07565 2 3.87867V3.18267C2 2.82267 2.256 2.512 2.61133 2.452C4.39171 2.15057 6.19428 1.99937 8 2Z"
        stroke="#2C4B94"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
