import Cookies from "js-cookie";
import { PLATFORM } from "../utils/constants/platform";

export const getUserInfor = () => {
  return JSON.parse(Cookies.get('userInfor') || "{}");
}

export const checkIsInstalledExt = () => {
  const domExt = document.querySelectorAll<HTMLInputElement>(
    '[name="9hub"]'
  );
  let isInstalled = false;
  if (domExt && domExt.length) {
    domExt.forEach((item) => {
      if (item.value === 'pnggbmbenebnjldiocnpklocljgageeo') {
        isInstalled = true;
        return;
      }
    })
  }
  return isInstalled
}

export const getDomainForCookie = () => {
  switch (getDomainApp()) {
    case "https://chat.so9.vn":
      return process.env.REACT_APP_COOKIE_DOMAIN;
    case "https://chat.so9.io":
      return process.env.REACT_APP_COOKIE_DOMAIN_GLOBAL;
    default:
      return process.env.REACT_APP_COOKIE_DOMAIN;
  }
}


export const getRedirectUrl = () => {
  switch (getDomainApp()) {
    case "https://chat.so9.vn":
      return process.env.REACT_APP_CHAT_URL;
    case "https://chat.so9.io":
      return process.env.REACT_APP_CHAT_URL_GLOBAL;
    default:
      return process.env.REACT_APP_CHAT_URL;
  }
}

export const getDomainLogin = () => {
  switch (getDomainApp()) {
    case "https://chat.so9.vn":
      return "https://app.so9.vn";
    case "https://chat.so9.io":
      return "https://app.so9.io";
    default:
      return "https://app.so9.vn";
  }
}

export const getDefaultLanguage = () => {
  switch (getDomainApp()) {
    case "https://chat.so9.vn":
      return "vi";
    case "https://chat.so9.io":
      return "en";
    default:
      return "vi";
  }
}

export const getDomainApp = () => {
  return window.location.origin;
}

export const getPlatformLabel = (platform: number) => {
  switch (platform) {
    case PLATFORM.PLATFORM_FACEBOOK:
      return "Facebook";
    case PLATFORM.PLATFORM_FACEBOOK_GROUP:
      return "Facebook";
    case PLATFORM.PLATFORM_INSTAGRAM:
      return "Instagram";
    case PLATFORM.PLATFORM_TIKTOK:
      return "Tiktok";
    case PLATFORM.PLATFORM_ZALO_OA:
      return "Zalo OA";
    default:
      return "";
  }
}