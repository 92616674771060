export default function IconSearch() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3116_16006)">
        <path d="M17.686 16.2255L12.2609 10.7994C13.0953 9.70381 13.5648 8.29519 13.5648 6.7819C13.5648 3.02559 10.5392 0 6.7829 0C3.02659 0 0 3.02659 0 6.7829C0 10.5392 3.02559 13.5648 6.7819 13.5648C8.29519 13.5648 9.65097 13.0953 10.7994 12.2609L16.2255 17.687C16.4339 17.8953 16.6951 18 16.9562 18C17.2174 18 17.4776 17.8953 17.687 17.687C18.1037 17.2693 18.1037 16.6432 17.686 16.2255L17.686 16.2255ZM6.7829 11.4783C4.17401 11.4783 2.08751 9.39178 2.08751 6.7829C2.08751 4.17401 4.17401 2.08751 6.7829 2.08751C9.39178 2.08751 11.4783 4.17401 11.4783 6.7829C11.4783 9.39078 9.39081 11.4783 6.7829 11.4783Z" fill="#9CA3AF"/>
      </g>
      <defs>
        <clipPath id="clip0_3116_16006">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}