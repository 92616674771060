export default function AutoHide() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1106_646)">
        <path
          d="M11.1957 1.29379L10.7103 0.808331C10.5732 0.671258 10.3198 0.692038 10.1615 0.882065L8.47203 2.56064C7.71192 2.23354 6.87808 2.07518 6.00168 2.07518C3.39426 2.08557 1.13471 3.60578 0.0475067 5.79109C-0.0158356 5.92866 -0.0158356 6.09742 0.0475067 6.2137C0.554245 7.24846 1.31435 8.10358 2.26449 8.74739L0.881344 10.1513C0.722989 10.3097 0.701706 10.563 0.80761 10.7001L1.29307 11.1856C1.43014 11.3226 1.68351 11.3019 1.84186 11.1118L11.1111 1.84257C11.312 1.68471 11.3328 1.43134 11.1957 1.29377L11.1957 1.29379ZM6.56137 4.48218C6.38173 4.44012 6.19171 4.38717 6.01258 4.38717C5.1154 4.38717 4.39735 5.10522 4.39735 6.0024C4.39735 6.18204 4.43941 6.37206 4.49236 6.5512L3.78471 7.24796C3.5734 6.8783 3.45761 6.46657 3.45761 6.0024C3.45761 4.59848 4.58739 3.46871 5.9913 3.46871C6.45598 3.46871 6.86771 3.585 7.23687 3.79581L6.56137 4.48218Z"
          fill="#9CA3AF"
        />
        <path
          d="M11.956 5.79108C11.5863 5.05226 11.1008 4.38716 10.4991 3.84875L8.53548 5.79108V6.00239C8.53548 7.40631 7.40571 8.53608 6.00179 8.53608H5.79048L4.54492 9.78165C5.0096 9.87666 5.49506 9.94 5.97012 9.94C8.57754 9.94 10.8371 8.41979 11.9243 6.22409C12.0193 6.07612 12.0193 5.92866 11.956 5.79108Z"
          fill="#9CA3AF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1106_646">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
