import { ConfigProvider } from "antd"
import ChannelViewType from "../components/channel-view-type"

export default function ChannelViewTypePage() {
    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Switch: {
                            colorPrimary: '#0AB39C',
                            colorPrimaryHover: '#0AB39C',
                        },
                    }
                }}
            >
                <ChannelViewType />
            </ConfigProvider>
        </>
    )
}