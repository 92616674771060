import {
  Radio,
  RadioChangeEvent,
  Select,
  Typography,
  Form,
  FormInstance,
} from "antd";
import { useEffect, useState } from "react";
import { ItemHeader } from "./ItemHeader";
import { getListGroupChannels, loadListChannel } from "../../../api/channel";
import { getUserInfor } from "../../../helper/common";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { useTranslation } from "react-i18next";

enum CHANNEL_TYPE {
  CHANNEL = 1,
  GROUP = 2,
}

export const ItemApplicableObject = ({
  itemSelectedToEdit,
  setData,
  form,
}: {
  itemSelectedToEdit?: ICreateAutomaticScript | IUpdateAutomaticScrip | any;
  setData: any;
  form: FormInstance;
}) => {
  const userInfo = getUserInfor();
  const [channelType, setChannelType] = useState<CHANNEL_TYPE>(
    itemSelectedToEdit?.channel?.type || CHANNEL_TYPE.CHANNEL
  );

  const conditionTypeChannel = () => {
    return itemSelectedToEdit?.channel?.type === CHANNEL_TYPE.CHANNEL;
  };

  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>(
    conditionTypeChannel() ? itemSelectedToEdit?.channel?.ids : []
  );

  const [selectedGroupItems, setSelectedGroupItems] = useState<string[]>(
    !conditionTypeChannel() ? itemSelectedToEdit?.channel?.ids : []
  );
  const [options, setOptions] = useState<any[]>([]);

  const onChange = (e: RadioChangeEvent) => {
    setChannelType(e.target.value);
    setData((pre: any) => ({
      ...pre,
      channel: {
        type: e.target.value,
        ids:
          e.target.value === CHANNEL_TYPE.CHANNEL
            ? selectedItems
            : selectedGroupItems,
      },
      channelIds:
        e.target.value === CHANNEL_TYPE.CHANNEL
          ? selectedItems
          : selectedGroupItems,
    }));

    form.setFieldsValue({
      channel_ids:
        e.target.value === CHANNEL_TYPE.CHANNEL
          ? selectedItems
          : selectedGroupItems,
    });
  };

  const getOptionChannel = async () => {
    let data: any = [];

    let channelInfoSelected: any[] = [];
    if (itemSelectedToEdit) {
      channelInfoSelected = itemSelectedToEdit.channel_info?.full_info || [];
    }

    if (channelType === CHANNEL_TYPE.CHANNEL) {
      channelInfoSelected = channelInfoSelected.map((i: any) => ({
        ...i,
        _id: i.id,
      }));
      data = await loadListChannel(userInfo.last_project_active, {
        status: 1,
        is_error: 0,
        // is_have_setting_script: 1,
      });

      // if (!data.data.channel) {
      const arrayMerged = channelInfoSelected.length
        ? data.data.channel.concat(
            channelInfoSelected.filter(
              (item2: any) =>
                !data.data.channel.some((item1: any) => item1._id === item2._id)
            )
          )
        : data.data.channel;
      // }

      setOptions(arrayMerged);
    } else {
      data = await getListGroupChannels(userInfo.last_project_active, {
        // is_have_setting_script: 1,
      });

      const arrayMerged = channelInfoSelected.length
        ? data.data.concat(
            channelInfoSelected.filter(
              (item2: any) =>
                !data.data.some((item1: any) => item1._id === item2._id)
            )
          )
        : data.data;

      setOptions(arrayMerged);
    }
  };

  useEffect(() => {
    getOptionChannel();
  }, [channelType]);

  useEffect(() => {
    setData((pre: any) => ({
      ...pre,
      channel: {
        type: channelType,
        ids: conditionTypeChannel() ? selectedItems : selectedGroupItems,
      },
      channelIds: conditionTypeChannel() ? selectedItems : selectedGroupItems,
    }));

    form.setFieldsValue({
      channel_ids: conditionTypeChannel() ? selectedItems : selectedGroupItems,
    });
  }, [channelType, selectedItems, selectedGroupItems]);

  return (
    <>
      <div className={`flex p-4 bg-gray-100 items-center gap-2 rounded-t-lg`}>
        <ItemHeader title={t("campaign.applicable_object")} />
        <div className="pl-8 items-center">
          <Radio.Group onChange={onChange} value={channelType}>
            <Radio value={CHANNEL_TYPE.CHANNEL}>{t("filter.channel")}</Radio>
            <Radio className="pl-2" value={CHANNEL_TYPE.GROUP}>
              {t("filter.group-channel")}
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div className={`p-4 bg-white rounded-b-lg`}>
        <Form.Item
          // className="m-0"
          name="channel_ids"
          rules={[{ required: true, message: t("campaign.require_channel") }]}
        >
          <Select
            mode="multiple"
            placeholder={t("campaign.choose_channel")}
            value={conditionTypeChannel() ? selectedItems : selectedGroupItems}
            onChange={
              conditionTypeChannel() ? setSelectedItems : setSelectedGroupItems
            }
            style={{ width: "100%" }}
            options={options?.map((item) => ({
              value: item?._id,
              label: item?.name,
            }))}
          />
        </Form.Item>
      </div>
    </>
  );
};
