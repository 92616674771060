import IconFacebook from "../../assets/svg/IconFacebook";
import IconInstagram from "../../assets/svg/IconInstagram";
import IconTiktok from "../../assets/svg/IconTiktok";
import IconZalo from "../../assets/svg/IconZalo";
import IconYoute from "../../assets/svg/IconYoutube";
import IconStack from "../../assets/svg/IconStack";
import { PLATFORM } from "../../utils/constants/platform";

export const Icon = {
  [PLATFORM.PLATFORM_FACEBOOK]: <IconFacebook />,
  [PLATFORM.PLATFORM_FACEBOOK_GROUP]: <IconFacebook />,
  [PLATFORM.PLATFORM_INSTAGRAM]: <IconInstagram />,
  [PLATFORM.PLATFORM_TIKTOK]: <IconTiktok />,
  [PLATFORM.PLATFORM_ZALO_OA]: <IconZalo />,
  [PLATFORM.PLATFORM_YOUTUBE]: <IconYoute />,
  [PLATFORM.NULL]: <IconStack />
};

export const IconPlatform = ({
  platform,
}: {
  platform:
  | PLATFORM.PLATFORM_FACEBOOK
  | PLATFORM.PLATFORM_INSTAGRAM
  | PLATFORM.PLATFORM_TIKTOK
  | PLATFORM.PLATFORM_ZALO_OA
  | PLATFORM.PLATFORM_FACEBOOK_GROUP
  | PLATFORM.PLATFORM_YOUTUBE
  | PLATFORM.NULL;
}) => {
  return Icon[platform];
};
