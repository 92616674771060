export default function AutoReply() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4878 2.55005L0 7.12322L5.4878 11.6964V9.31834C5.79935 9.30405 11.2279 9.12898 15 12.4281C13.4901 7.777 8.74902 5.75346 5.4878 4.9281V2.55005Z"
        fill="#8E8E8E"
      />
    </svg>
  );
}
