import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {conversationSlice} from "../reducers/conversationSlice";
import {conversationScriptSlice} from "../reducers/conversationScriptSlice";
import { channels } from "../reducers/channel";

const rootReducer = combineReducers({
  conversation: conversationSlice.reducer,
  conversationScript: conversationScriptSlice.reducer,
  channels: channels.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer
});

