import { Outlet } from "react-router-dom";
import LeftMenuCampaign from "./LeftMenuCampaign";

export default function LayoutCampaign() {
  return (
    <div className={`flex bg-gray-200 overflow-hidden`}>
      <LeftMenuCampaign />
      <div
        className={`flex-1 h-max-screen overflow-auto bg-gray-200 p-8 text-left`}
      >
        <Outlet />
      </div>
    </div>
  );
}
