export const PAGE_TITLE = "Hub | SO9";

export const DAY_OF_WEEK = [
  "Chủ nhật",
  "Thứ 2",
  "Thứ 3",
  "Thứ 4",
  "Thứ 5",
  "Thứ 6",
  "Thứ 7",
];

export const DAY_OF_WEEK_EN = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const MONTH_OF_YEAR = [
  "Tháng 1",
  "Tháng 2",
  "Tháng 3",
  "Tháng 4",
  "Tháng 5",
  "Tháng 6",
  "Tháng 7",
  "Tháng 8",
  "Tháng 9",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12",
];

export const MONTH_OF_YEAR_EN = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const TYPE_CONTENT_CONTAIN_KEY_WORD = 1;
export const TYPE_CONTENT_NO_CONTAIN_KEY_WORD = 2;
export const TYPE_CONTENT_CONTAIN_AND_NO_CONTAIN_KEY_WORD = 3;
export const TYPE_CONTENT_IMAGE = 4;
export const TYPE_CONTENT_VIDEO = 5;
export const TYPE_CONTENT_LINK = 6;
export const TYPE_CONTENT_PHONE = 7;
export const TYPE_CONTENT_PHONE_AND_CONTAIN_KEY_WORD = 8;
export const TYPE_CONTENT_PHONE_AND_NO_CONTAIN_KEY_WORD = 9;

export const TYPE_CONTENT_LINK_AND_CONTAIN_KEY_WORD = 10;
export const TYPE_CONTENT_LINK_AND_NO_CONTAIN_KEY_WORD = 11;
export const TYPE_CONTENT_IMAGE_AND_CONTAIN_KEY_WORD = 12;
export const TYPE_CONTENT_IMAGE_AND_NO_CONTAIN_KEY_WORD = 13;

export const KEY_OPTIONS_KEYWORDS = {
  [TYPE_CONTENT_CONTAIN_KEY_WORD]: "CONTAIN",
  [TYPE_CONTENT_NO_CONTAIN_KEY_WORD]: "NOT_CONTAIN",
  [TYPE_CONTENT_CONTAIN_AND_NO_CONTAIN_KEY_WORD]: "CONTAIN_AND_NOT_CONTAIN",
  [TYPE_CONTENT_IMAGE]: "IMAGE",
  [TYPE_CONTENT_VIDEO]: "VIDEO",
  [TYPE_CONTENT_LINK]: "LINK",
  [TYPE_CONTENT_PHONE]: "PHONE_NUMBER",
  [TYPE_CONTENT_PHONE_AND_CONTAIN_KEY_WORD]: "PHONE_NUMBER_AND_CONTAIN",
  [TYPE_CONTENT_PHONE_AND_NO_CONTAIN_KEY_WORD]: "PHONE_NUMBER_AND_NOT_CONTAIN",
  [TYPE_CONTENT_LINK_AND_CONTAIN_KEY_WORD]: "LINK_AND_CONTAIN",
  [TYPE_CONTENT_LINK_AND_NO_CONTAIN_KEY_WORD]: "LINK_AND_NOT_CONTAIN",
  [TYPE_CONTENT_IMAGE_AND_CONTAIN_KEY_WORD]: "IMAGE_AND_CONTAIN",
  [TYPE_CONTENT_IMAGE_AND_NO_CONTAIN_KEY_WORD]: "IMAGE_AND_NOT_CONTAIN",
};
