import { Button, Typography, message, Form } from "antd";
import { useState } from "react";
import IconArrowLeft from "../../../assets/svg/RightConversation/IconArrowLeft";
import ItemCampaignName from "./ItemCampaignName";
import { ItemApplicableObject } from "./ItemApplicableObject";
import { AutomaticScript } from "./AutomaticScript";
import { CommentContent } from "./CommentContent";
import { MessageContent } from "./MessageContent";
import {
  ICreateAutomaticScript,
  IUpdateAutomaticScrip,
} from "../../../dto/automatic-script/request/request";
import { useTranslation } from "react-i18next";
import {
  createAutomaticBot,
  getListAutomaticBot,
  updateAutomaticBot,
} from "../../../api/automtic-script";
import { ActionType } from ".";
import { IListAutoScriptResponseData } from "../../../dto/automatic-script/response/response";
import { getUserInfor } from "../../../helper/common";
import { KeywordSetting } from "./Keywords";
export default function CreateBot({
  setIsOpenCreateOrEdit,
  isOpenCreateOrEdit,
  itemSelectedToEdit,
  setListAutoBot,
}: {
  setIsOpenCreateOrEdit: any;
  isOpenCreateOrEdit: ActionType;
  itemSelectedToEdit?: IListAutoScriptResponseData;
  setListAutoBot: any;
}) {
  const userInfo = getUserInfor();
  const initStateData:
    | ICreateAutomaticScript
    | IUpdateAutomaticScrip
    | IListAutoScriptResponseData
    | any
    | undefined = itemSelectedToEdit
    ? {
        automatic_script_id: itemSelectedToEdit._id,
        channel: itemSelectedToEdit.channel,
        channel_ids: itemSelectedToEdit.channel.ids,
        comment: itemSelectedToEdit.comment,
        is_auto_hide_comment: itemSelectedToEdit.is_auto_hide_comment,
        is_auto_like_comment: itemSelectedToEdit.is_auto_like_comment,
        is_auto_reply_comment: itemSelectedToEdit.is_auto_reply_comment,
        is_auto_send_message_after_reply_comment:
          itemSelectedToEdit.is_auto_send_message_after_reply_comment,
        is_auto_delete_comment: itemSelectedToEdit.is_auto_delete_comment,
        is_auto_block_customer: itemSelectedToEdit.is_auto_block_customer,
        message: itemSelectedToEdit.message,
        name: itemSelectedToEdit.name,
        project_id:
          itemSelectedToEdit.project_id || userInfo.last_project_active,
        channel_info: itemSelectedToEdit?.channel_info?.full_info?.length
          ? itemSelectedToEdit.channel_info
          : undefined,
        key_word_setting: itemSelectedToEdit.key_word_setting || {
          type: null,
          contain_key_words: [],
          no_contain_key_works: [],
        },
      }
    : undefined;

  const { t } = useTranslation();
  const [data, setData] = useState<
    ICreateAutomaticScript | IUpdateAutomaticScrip | undefined
  >(initStateData);

  const fetchData = async () => {
    const data = await getListAutomaticBot({
      projectId: userInfo.last_project_active,
      page: 1,
    });
    setListAutoBot(data.data);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCreateBot = async () => {
    if (data) {
      setIsLoading(true);
      if (!data.key_word_setting?.type || !data.key_word_setting?.is_active) {
        delete data.key_word_setting;
      }

      try {
        const result = await createAutomaticBot({
          ...data,
          project_id: userInfo.last_project_active,
        });
        if (result.status) {
          message.success(t("campaign.create_success"));
          await fetchData();
          setIsOpenCreateOrEdit(ActionType.OPEN_LIST);
        } else {
          message.error(t("campaign.create_error"));
        }
      } catch (error) {
        message.error(t("campaign.create_error"));
      }
      setIsLoading(false);
    }
  };
  const [form] = Form.useForm();

  const handleUpdateBot = async () => {
    if (data) {
      setIsLoading(true);
      try {
        if (!data.key_word_setting?.type) {
          delete data.key_word_setting;
        }

        const result = await updateAutomaticBot(data as IUpdateAutomaticScrip);
        if (result.status) {
          message.success(t("campaign.edit_success"));
          await fetchData();
          setIsOpenCreateOrEdit(ActionType.OPEN_LIST);
        } else {
          message.error(t("campaign.edit_error"));
        }
        setIsLoading(false);
      } catch (error) {
        message.error(t("campaign.edit_error"));
      }
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="flex justify-center">
        <div className="flex items-center overflow-hidden w-[100%] min-w-[100%]">
          <div
            className="cursor-pointer"
            onClick={() => setIsOpenCreateOrEdit(ActionType.OPEN_LIST)}
          >
            <IconArrowLeft />
          </div>
          <Typography className={`pl-2 font-[600] text-lg`}>
            {isOpenCreateOrEdit === ActionType.OPEN_CREATE
              ? t("campaign.create")
              : t("campaign.edit_bot")}
          </Typography>
        </div>
      </div>
      <Form
        initialValues={initStateData}
        form={form}
        onFinish={
          isOpenCreateOrEdit === ActionType.OPEN_CREATE
            ? handleCreateBot
            : handleUpdateBot
        }
      >
        <div className="flex justify-center">
          <div className={"mt-4 w-[100%] min-w-[100%]"}>
            <ItemCampaignName itemSelectedToEdit={data} setData={setData} />
          </div>
        </div>
        <div className="mt-6">
          <ItemApplicableObject
            itemSelectedToEdit={data}
            setData={setData}
            form={form}
          />
        </div>

        <div className="mt-6">
          <KeywordSetting itemSelectedToEdit={data} setData={setData} />
        </div>

        <div className="mt-6">
          <AutomaticScript itemSelectedToEdit={data} setData={setData} />
        </div>
        <div className="mt-6 flex justify-between">
          {data && !!data.is_auto_reply_comment && (
            <CommentContent itemSelectedToEdit={data} setData={setData} />
          )}
          {data && !!data.is_auto_send_message_after_reply_comment && (
            <MessageContent itemSelectedToEdit={data} setData={setData} />
          )}
        </div>
        <div className="my-4">
          <Button
            key="back"
            onClick={() => setIsOpenCreateOrEdit(ActionType.OPEN_LIST)}
          >
            {t("button.cancel")}
          </Button>

          <Button
            key="submit"
            // loading={creatingTopic}
            onSubmit={
              isOpenCreateOrEdit === ActionType.OPEN_CREATE
                ? handleCreateBot
                : handleUpdateBot
            }
            disabled={!data}
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className={`bg-blue-500 hover:bg-green-400 ms-2`}
          >
            {t("button.save")}
          </Button>
        </div>
      </Form>
    </div>
  );
}
