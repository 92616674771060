import {
  ICreateAutomaticScript,
  IDeleteAutomaticScript,
  IUpdateAutomaticScrip,
} from "../dto/automatic-script/request/request";
import { API } from "../libs/client";

/**
 * @param {{params: {status:number}}} params
 * @param {string} projectId
 * @returns
 */

export const getListAutomaticBot = async ({
  projectId,
  page,
  search,
  status,
  channel_ids,
  group_ids
}: {
  projectId: string;
  page: number;
  search?: string;
  status?: number;
  channel_ids?: string[],
  group_ids?:string[]
}) => {
  const uri = `/projects/bot-chat/conversations/automatic-script/list`;
  const res = await API.get(uri, {
    params: { project_id: projectId, page, search, status, channel_ids, group_ids },
  });
  return res.data;
};

export const createAutomaticBot = async (data: ICreateAutomaticScript) => {
  const uri = `/projects/bot-chat/conversations/automatic-script`;
  const res = await API.post(uri, data);
  return res.data;
};

export const updateAutomaticBot = async (data: IUpdateAutomaticScrip) => {
  const uri = `/projects/bot-chat/conversations/automatic-script/update`;
  const res = await API.put(uri, data);
  return res.data;
};

export const deleteAutomaticBot = async (params: IDeleteAutomaticScript) => {
  const uri = `/projects/bot-chat/conversations/automatic-script/delete`;
  const res = await API.delete(uri, { params });
  return res.data;
};
