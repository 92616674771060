import { API } from "../libs/client";


/**
 * @param {{params: {status:number}}} params
 * @param {string} projectId
 * @returns
 */

export const loadListChannel = async (projectId:string, params = {}) => {
    const uri = `/projects/${projectId}/channels/list`;
    const res = await API.get(uri, { params });
    return res.data;
};

/**
 * @param {string} projectId
 * @returns
 */

export const getListChannelsTotal = async (projectId:string, params = {}) => {
    const uri = `/projects/${projectId}/setting-sync-webhooks/list-channel`;
    const res = await API.get(uri, { params });
    return res.data;
}

export const getListChannels = async (projectId:string, page:number, params = {}) => {
    const uri = `projects/${projectId}/setting-sync-webhooks/list?page=${page}`;
    const res = await API.get(uri, { params });
    return res.data;
}

/**
 * @param {{params: {channel_ids:array, is_sync_chat: bool, is_sync_comment: bool}}} params
 * @param {string} projectId
 * @returns
 */

export const updateBulkChannelWebHook = async (projectId:string, data = {}) => {
    const uri = `projects/${projectId}/setting-sync-webhooks/bulk-update`;
    const res = await API.post(uri, data);
    return res.data;
}

/**
 * @param {string} projectId
 * @param {string} _id
 * @returns
 */

export const updateChannelWebHook = async (projectId:string, _id:string, data = {}) => {
    const uri = `projects/${projectId}/setting-sync-webhooks/${_id}/update`;
    const res = await API.post(uri, data);
    return res.data;
}

/**
 * @param {string} projectId
 * @returns
 */

export const getListGroupChannels = async (projectId:string, params = {}) => {
    const uri = `projects/${projectId}/group-channels/list`;
    const res = await API.get(uri, { params });
    return res.data;
  };