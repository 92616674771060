import { Typography } from "antd";
import { IconTopic } from "../../../assets/svg/ConversationScript/IconConersationScript";

export const ItemHeader = ({ title }: { title: string }) => {
  return (
    <>
      <div>
        <IconTopic />
      </div>
      <Typography className={`text-sm font-semibold`}>{title}</Typography>
    </>
  );
};
