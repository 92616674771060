import React, { Suspense, useEffect } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Layout from "./layout";
import LayoutSetting from "./layout/LayoutSetting";
import PageLoading from "./components/shared/PageLoading";
import useSocketListener from "./hooks/useSocketListener";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_TITLE } from './utils/constants/common';
import Cookies from 'js-cookie';
import { getListGroupChannels, loadListChannel } from './api/channel';
import { setChannels, setGroupChannels } from './reducers/channel';
import { getListTag, getSettingNotifycation } from './api/conversationScript';
import { setNotifySetting, setTags } from './reducers/conversationScriptSlice';
import ConversationTagPage from './pages/ConversationTagPage';
import SoundNotificationSetting from './pages/SoundNotificationSetting';
import { RootState } from './store';
import LayoutCampaign from './layout/Campaign/LayoutCampaign';
import Campaign from './pages/Campaign';
import moment from 'moment-timezone';
import "moment/locale/en-gb";
import { getProject } from './api/project';
import ChannelViewTypePage from "./pages/ChannelViewTypePage";
import { getUserInfor } from "./helper/common";

const Home = React.lazy(() => import('./pages/Home'));
const ConversationScriptPage = React.lazy(() => import('./pages/ConversationScriptPage'));

function App() {
  const dispatch = useDispatch()
  useSocketListener(dispatch)
  document.title = PAGE_TITLE

  const userInfor = getUserInfor();

  const fetchListChannels = async () => {

    loadListChannel(userInfor.last_project_active, {
      status: 1
    })
      .then((response) => {
        if (response.status) {
          dispatch(setChannels(response?.data?.channel))
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  const fetchListGroupChannels = async () => {

    getListGroupChannels(userInfor.last_project_active)
      .then((response) => {
        if (response.status) {
          dispatch(setGroupChannels(response?.data))
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  const fetchListTags = async () => {
    const data={
      params:{
        project_id: userInfor.last_project_active
      }
    }
    getListTag(data)
      .then((response) => {
        if (response.status) {
          dispatch(setTags(response?.data))
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  }
 
  const fetchTimezone = async () => {
    getProject(userInfor.last_project_active)
    .then((response) => {
      let timezone = 'Asia/Ho_Chi_Minh';
      if (response.status && response.time_zone) {
        timezone = response.time_zone;
      }
      moment.tz.setDefault(timezone);
    })
    .catch((error: any) => {
      console.error(error);
    });
  }

  useEffect(() => {
    fetchTimezone();
    fetchListChannels();
    fetchListGroupChannels();
    fetchListTags();
  }, [])

  return (
    <div className={'App'}>
      <Routes>
        <Route element={<Layout />}>
          <Route path={'/'} element={
            <Suspense fallback={<PageLoading />}>
              <Home />
            </Suspense>
          } />

          <Route path={'setting'} element={<LayoutSetting />}>
            <Route path={'conversation-script'} element={
              <Suspense fallback={<PageLoading />}>
                <ConversationScriptPage />
              </Suspense>
            } />
          </Route>
          <Route path={'setting'} element={<LayoutSetting />}>
            <Route path={'conversation-tag'} element={
              <Suspense fallback={<PageLoading />}>
                <ConversationTagPage />
              </Suspense>
            } />
          </Route>
          <Route path={'setting'} element={<LayoutSetting />}>
            <Route path={'sound-notification'} element={
              <Suspense fallback={<PageLoading />}>
                <SoundNotificationSetting/>
              </Suspense>
            } />
          </Route>
          <Route path={'setting'} element={<LayoutSetting />}>
            <Route path={'channel-view-type'} element={
              <Suspense fallback={<PageLoading />}>
                <ChannelViewTypePage/>
              </Suspense>
            } />
          </Route>

          <Route path={'campaign'} element={<LayoutCampaign />}>
            <Route path={'bot-auto'} element={
              <Suspense fallback={<PageLoading />}>
                <Campaign/>
              </Suspense>
            } />
          </Route>

        </Route>
      </Routes>
    </div>
  );
}

export default App;
