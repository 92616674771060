import { API } from "../libs/client";

/**
 * @param {string} projectId
 * @returns
*/

export const getProject = async (projectId: string, params = {}) => {
    const uri = `/projects/${projectId}/show`;
    const res = await API.get(uri, { params });
    return res.data.data;
  };