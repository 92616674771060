export default function AutoSendMessage() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1099_614)">
        <path
          d="M10.4313 0C11.1906 0 11.8164 0.625805 11.8164 1.38507V7.54093C11.8164 8.3002 11.1906 8.926 10.4313 8.926H8.91699L9.1815 11.4942C9.20074 11.6818 9.09133 11.876 8.9212 11.9571C8.75107 12.0383 8.53166 12.0016 8.3976 11.8694L5.44463 8.92608H1.50519C0.745922 8.92608 0.120117 8.30027 0.120117 7.54101V1.38514C0.120117 0.62588 0.745922 7.54384e-05 1.50519 7.54384e-05L10.4313 0ZM10.4313 0.92338H1.50529C1.24139 0.92338 1.0436 1.12117 1.0436 1.38507V7.54093C1.0436 7.80484 1.24139 8.00262 1.50529 8.00262H5.63711C5.75794 8.00262 5.87817 8.05192 5.96414 8.13728L8.13316 10.2966L7.95041 8.50756C7.92636 8.25327 8.1518 8.00439 8.4073 8.00259H10.4313C10.6952 8.00259 10.893 7.8048 10.893 7.5409V1.38504C10.893 1.12114 10.6952 0.923349 10.4313 0.923349L10.4313 0.92338ZM9.35402 2.00066C9.60891 2.00066 9.81571 2.20746 9.81571 2.46235C9.81571 2.71723 9.60891 2.92404 9.35402 2.92404H2.58257C2.32769 2.92404 2.12088 2.71723 2.12088 2.46235C2.12088 2.20746 2.32769 2.00066 2.58257 2.00066H9.35402ZM9.35402 4.00131C9.60891 4.00131 9.81571 4.20812 9.81571 4.463C9.81571 4.71789 9.60891 4.92469 9.35402 4.92469H2.58257C2.32769 4.92469 2.12088 4.71789 2.12088 4.463C2.12088 4.20812 2.32769 4.00131 2.58257 4.00131H9.35402ZM9.35402 6.00197C9.60891 6.00197 9.81571 6.20877 9.81571 6.46366C9.81571 6.71854 9.60891 6.92535 9.35402 6.92535H2.58257C2.32769 6.92535 2.12088 6.71854 2.12088 6.46366C2.12088 6.20877 2.32769 6.00197 2.58257 6.00197H9.35402Z"
          fill="#9CA3AF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1099_614">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
