import DefaultImg from "../../assets/img/no-image.png";

const CustomImg = ({src, className, alt, size, defaultImg, ...props} : any) => {
    return (
        <img 
        src={src}
        className={className} 
        alt={alt} 
        width={size} 
        height={size} 
        {...props}
        onError={({currentTarget}) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultImg || DefaultImg;
        }}
        />
    )
}

export default CustomImg;