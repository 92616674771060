import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IReplySampleItem} from "../dto/reply-sample";
import {IReplyTopicItem} from "../dto/reply-topic";
import { tagItem } from "../dto";

interface IInitialState {
  replySamples: IReplySampleItem[] | undefined;
  replyTopics: IReplyTopicItem[] | undefined;
  tags: tagItem[] | undefined;
  notifySetting : {
    is_enable_sound_remind: number, 
    is_enable_sound_message: number,
    isload : boolean,
    _id : string,
  } | undefined;
}

const initialState : IInitialState = {
  replySamples : undefined,
  replyTopics: undefined,
  tags: undefined,
  notifySetting:undefined,
};


export const conversationScriptSlice = createSlice({
  name: "conversationScript",
  initialState,
  reducers: {
    setReplySamples(state: any, action: PayloadAction<IReplySampleItem[] | undefined>){
      state.replySamples = action.payload
    },
    setReplyTopics(state: any, action: PayloadAction<IReplyTopicItem[] | undefined>){
      state.replyTopics = action.payload
    },
    setTags(state: any, action: PayloadAction<tagItem[] | undefined>){
      state.tags = action.payload
    },
    setNotifySetting(state: any, action: PayloadAction<  {
      is_enable_sound_remind: number,
      is_enable_sound_message: number,
    } | undefined>){
      state.notifySetting = action.payload
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(fetchOneConversation.fulfilled, (state, action) => {
  //     state.conversationList.unshift(action.payload);
  //   });
  // },
});

export const {
  setReplySamples,
  setReplyTopics,
  setTags,
  setNotifySetting
} = conversationScriptSlice.actions;
export default conversationScriptSlice.reducer;
