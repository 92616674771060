export default function IconFacebook() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z"
        fill="url(#paint0_linear_652_1485)"
      />
      <path
        d="M1 2.6C1 2.03995 1 1.75992 1.10899 1.54601C1.20487 1.35785 1.35785 1.20487 1.54601 1.10899C1.75992 1 2.03995 1 2.6 1H13.4C13.9601 1 14.2401 1 14.454 1.10899C14.6422 1.20487 14.7951 1.35785 14.891 1.54601C15 1.75992 15 2.03995 15 2.6V13.4C15 13.9601 15 14.2401 14.891 14.454C14.7951 14.6422 14.6422 14.7951 14.454 14.891C14.2401 15 13.9601 15 13.4 15H2.6C2.03995 15 1.75992 15 1.54601 14.891C1.35785 14.7951 1.20487 14.6422 1.10899 14.454C1 14.2401 1 13.9601 1 13.4V2.6Z"
        fill="url(#paint1_linear_652_1485)"
      />
      <path
        d="M10.6069 10.1408L10.9178 8.16505H8.97261V6.8835C8.97261 6.34285 9.24383 5.81553 10.1151 5.81553H11V4.1335C11 4.1335 10.1973 4 9.43013 4C7.82742 4 6.78083 4.94647 6.78083 6.65922V8.16505H5V10.1408H6.78083V14.9172C7.13835 14.972 7.50412 15 7.87672 15C8.24932 15 8.61509 14.972 8.97261 14.9172V10.1408H10.6069Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_652_1485"
          x1="-19"
          y1="4.25"
          x2="19.5"
          y2="-1.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0E88F0" />
          <stop offset="1" stop-color="#097BEB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_652_1485"
          x1="-19"
          y1="4.25"
          x2="19.5"
          y2="-1.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0E88F0" />
          <stop offset="1" stop-color="#097BEB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
